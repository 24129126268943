import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useCreateUsersMutation } from "redux/api/Users/usersApi";

const initialValues = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  roleId: "",
  birthday: "",
  password: "",
};





export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [disabled, setDisabled] = useState(false);
  const [defaultState, setDefaultState] = useState(false);
  const form = useRef(null);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showRePassword: false,
  });
  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    createUsers,
    { isLoading, error, isSuccess, isError },
  ] = useCreateUsersMutation();

  const [phone, setPhone] = React.useState("");
  function handleChangePhoneNumber(e) {
    setPhone(e.target.value);
  }
  const CreateSchema = Yup.object().shape({
    firstName: Yup.string().required("Ad daxil etməyiniz tələb olunur."),
    lastName: Yup.string().required("Soyad daxil etməyiniz tələb olunur."),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password must consist of an uppercase letter, a lowercase letter, a number and a character."
      )
      .required("Parol daxil etməyiniz tələb olunur"),
    email: Yup.string()
      .email("Wrong email format. E.g. jhon.doe@example.com")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("İstifadəçinin e-poçt ünvanını daxil etməyiniz tələb olunur."),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostUser = (values, resetForm, setSubmitting) => {
    const asset = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      password: values?.password,
    };

    createUsers(asset);

    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostUser(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();

    }
   
  }, [isLoading]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="Adı"
                    placeholder="Adı"
                    className="w-100"
                    error={getInputClasses("firstName")}
                    {...formik.getFieldProps("firstName")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.firstName}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="lastName"
                    label="Soyadı"
                    placeholder="Soyadı"
                    className="w-100"
                    error={getInputClasses("lastName")}
                    {...formik.getFieldProps("lastName")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    autocomplete="new-password"
                    id="email"
                    label="E-poçt ünvanı"
                    placeholder="E-poçt ünvanı"
                    className="w-100"
                    error={getInputClasses("email")}
                    {...formik.getFieldProps("email")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="password"
                    autocomplete="new-password"
                    className="w-100"
                    type={values.showPassword ? "text" : "password"}
                    label="Şifrə"
                    error={getInputClasses("password")}
                    {...formik.getFieldProps("password")}
                    onKeyDown={handleEnter}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showPassword")
                            }
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Əlavə et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
