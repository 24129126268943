import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addPermission } from "../../../../redux/features/Role/RoleSlicer";

const Scope = ({
  name,
  label,

  permissonId,
  parameters,
  changeRadioButton,
  oneChoice,
  setSelect,
  select,
  chooseScope,
}) => {
  

  const dispatch = useDispatch();
  return (
    <div
      className={`accordion accordion-toggle-arrow w-100 mb-2 ${
        chooseScope[name] !== undefined &&
        !chooseScope[name].valid &&
        chooseScope[name].touch
          ? "border border-danger rounded"
          : ""
      }`}
      id={permissonId}
    >
      <div className="card">
        <div className="card-header">
          <div
            className="card-title"
            data-toggle="collapse"
            aria-expanded="false"
            data-target={`#collapseOne${permissonId}`}
          >
            {label}
          </div>
        </div>
        <div
          id={`#collapseOne${permissonId}`}
          className="show"
          data-parent="#accordionExample1"
        >
          <div className="card-body">
            <div className="form-group mb-0">
              <div className="checkbox-list">
                {parameters.length ? (
                  parameters.map(({ id, description, value ,checked}) => (
                    <label key={id} className="checkbox">
                      <input
                        type="radio"
                        name={name}
                        value={id}
                        checked={checked}
                        onChange={(e) => {
                       

                          changeRadioButton(
                            name,
                            true,
                            true,
                            permissonId,
                            value,
                            id
                          );
                          dispatch(
                            addPermission({
                              id: permissonId,
                              value: value,
                              scopeId: id,
                            })
                          );
                        }}
                      />
                      <span></span>
                      {value}
                    </label>
                  ))
                ) : (
                  <label className="checkbox">
                    <input
                      type="radio"
                      name={label}

                      onChange={(e,checked) => {
                        changeRadioButton(label, true, true, permissonId);
      
                        dispatch(addPermission({ id: permissonId }));
                      }}
                    />
                    <span></span>
                    {label}
                  </label>
                )}
                {chooseScope[name] !== undefined &&
                !chooseScope[name].valid &&
                chooseScope[name].touch ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {/*{chooseScope[name].message}*/}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scope;
