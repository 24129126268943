import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useDeleteNotificationMutation } from "redux/api/NewsSources/newsSourcesApi";
import { setErrorCount } from "redux/features/NewsSources/newsSourcesSlices";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100% !important",
    height: "100px !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));



export default function Remove(props) {
  const classes = useStyles();
  const { onHide, show, id} = props;
  const dispatch = useDispatch()
  const errorCount = useSelector((state) => state.sources.errorCount);
  const [deleteNotification, { isLoading, error, isSuccess, }] =
  useDeleteNotificationMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setErrorCount(errorCount-1));
      onHide();
    }
   
    }, [isLoading]);

  const onDeleteUser = (e) => {
    e.preventDefault();
    deleteNotification(id)
  
  };


  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteUser}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4 mb-3">
            <div className="row">Silmək istədiyinizdən əminsinizmi?</div>
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="light" 
            onClick={() => {
              onHide(false);
             
            }}
          >
          Bağla
          </Button>
          <Button type="submit" variant="danger">Sil</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
