import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { setUsers, setPermissions } from "../../features/Users/usersSlice";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query(data) {
        const { skip, take, field, order, name } = data;
        return {
          url: `users?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${order}${
            name?.firstName ? "&FirstName=" + name?.firstName : ""
          }${name?.lastName ? "&LastName=" + name?.lastName : ""}${
            name?.email ? "&Email=" + name?.email : ""
          }${
            name?.deleted === true
              ? `&Deleted=${name?.deleted}`
              : name?.deleted === false
              ? `&Deleted=${name?.deleted}`
              : ""
          }${name?.FromDate ? "&FromDate=" + name?.FromDate : ""}${
            name?.ToDate ? "&ToDate=" + name?.ToDate : ""
          }`,
        };
      },

      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setUsers(data.data));
        } catch (error) {}
      },
    }),
    getUsersPermissons: builder.query({
      query(data) {
        return {
          url: `users/permissions`,
        };
      },

      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setPermissions(data));
        } catch (error) {}
      },
    }),
    getChooseUser: builder.query({
      query(id) {
        return {
          url: `users/${id}`,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateUserPassword: builder.mutation({
      query(data) {
        return {
          url: "users/change-password",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updatePassword: builder.mutation({
      query(data) {
        return {
          url: "users/updatePassword",
          method: "PUT",
          data: data,
        };
      },

      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateUsers: builder.mutation({
      query(data) {
        return {
          url: "users",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateStatus: builder.mutation({
      query(data) {
        return {
          url: "users/updateStatus",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createUsers: builder.mutation({
      query(form) {
        return {
          url: "users",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteUsers: builder.mutation({
      query(id) {
        return {
          url: `users/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetChooseUserQuery,
  useCreateUsersMutation,
  useUpdateUsersMutation,
  useUpdatePasswordMutation,
  useUpdateStatusMutation,
  useGetUsersPermissonsQuery,
  useUpdateUserPasswordMutation,
  useDeleteUsersMutation,
} = usersApi;
