import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DatePicker from "app/modules/UI/Datetimepicker";
import { useGetCategoriesQuery } from "redux/api/Categories/categoriesApi";
import ReactSelect from "app/modules/UI/ReactSelect";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage,
  } = props;
  const history = useHistory();
  const { data } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  const [categoriesOptions, setCategoriesOptions] = React.useState(null);
  const [categoriesOption, setCategoriesOption] = React.useState(null);

  const updateUrl = (newFilterInputs) => {
    const params = new URLSearchParams(newFilterInputs).toString();
    history.push(`?${params}`);
  };

  const handleFilterChange = (e) => {
    const updatedFilters = { ...filterInputs, [e.target.name]: e.target.value };
    setFilterInputs(updatedFilters);
    filterInputsUpdate(e);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeStartDate = (e) => {
    const updatedFilters = { ...filterInputs, FromDate: e.target.value };
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeEndDate = (e) => {
    const updatedFilters = { ...filterInputs, ToDate: e.target.value };
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeCategories = (value) => {
    const updatedFilters = { ...filterInputs, parentCategoryId: value?.value };
    setCategoriesOption(value);
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const onResetFilter = () => {
    const resetFilters = {
      name: "",
      parentCategoryId: "",
      FromDate: "",
      ToDate: "",
    };
    setCategoriesOption(null);
    setFilterInputs(resetFilters);
    updateUrl(resetFilters);
  };

  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoriesOptions([
        { label: "Heçbiri", value: null },
        ...newSelectData,
      ]);
    }
  }, [data]);

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Kataqoriya filtri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="name"
                  name="name"
                  label="Ad"
                  placeholder="Ad"
                  className="w-100"
                  value={filterInputs.name}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                  suggestions={categoriesOptions}
                  handleChangeSingle={handleChangeCategories}
                  single={categoriesOption}
                  label="Üst Kataqoriya"
                  placeholder="Üst Kataqoriya"
                  inputId="roleId"
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.FromDate}
                  label="Başlanğıc tarix"
                  onChange={handleChangeStartDate}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.ToDate}
                  label="Son tarix"
                  onChange={handleChangeEndDate}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onResetFilter}>
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
