import {createSlice} from "@reduxjs/toolkit"




const initialState= {
    users: [],
    user1:{},
    permissions:[],
}

export const usersSlice = createSlice({
    initialState,
    name: "usersSlice",
    reducers: {
        setUsers: (state,action) => {
            state.users =action.payload
        },
        setUser1 : (state,action)=>{
            state.user1 = action.payload
        },
        setPermissions : (state,action)=>{
            state.permissions = action.payload
        }
    }
})

export default usersSlice.reducer;
export const {setUsers,setUser1,setPermissions} = usersSlice.actions