import React from "react";
import TextField from "@material-ui/core/TextField";
import {Button } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import PlusIcon from "@material-ui/icons/Add";

const ParticipantInformation = ({

  getFieldProps,
  values,
  touched,
  errors,
  addProtocolParticipants,
  handleRemoveParticipantItem,
}) => {
  return (
    <div>
      {values?.searchHelperKeywords?.map((participant, index, key) => {
        const participantError =
          (errors.searchHelperKeywords?.length &&
            errors.searchHelperKeywords[index]) ||
          {};
        const participantTouch =
          (touched.searchHelperKeywords?.length &&
            touched.searchHelperKeywords[index]) ||
          {};

        return (
          <div className="row mt-2">
            <div className="col col-6">
              {" "}
              <TextField
                label="İfadə"
                className="w-100"
                {...getFieldProps(`searchHelperKeywords[${index}].word`)}
              />
              {participantTouch.word ||
              (participantTouch.word && participantError.word) ||
              participantError.word ? (
                <div className="mt-2">
                  <div className="text-danger">
                    {participantError["word"]
                      ? participantError["word"]
                      : participantError["word"]}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col col-5">
              {" "}
              <TextField
                label="Təsviri"
                className="w-100"
                {...getFieldProps(`searchHelperKeywords[${index}].description`)}
              />
              {participantTouch.description ||
              (participantTouch.description && participantError.description) ||
              participantError.description ? (
                <div className="mt-2">
                  <div className="text-danger">
                    {participantError["description"]
                      ? participantError["description"]
                      : participantError["description"]}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col col-1">
              <span
                aria-label="Delete"
                className="btn mt-5 ml-5 btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                onClick={() => {
                  handleRemoveParticipantItem(index);
                }}
              >
                <DeleteIcon />
              </span>
            </div>
          </div>
        );
      })}

      <div className="col col-6 d-flex align-items-end mt-4 p-0">
        <Button onClick={addProtocolParticipants} className={`px-3`}>
          <PlusIcon /> Açar söz əlavə et
        </Button>
      </div>
    </div>
  );
};

export default ParticipantInformation;
