import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import RenderIf from "../UI/RenderIf";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import { MultiSelect } from "react-multi-select-component";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  useGetEmployeeNewsSourcesQuery,
  useCreateEmployeeNewsSourcesMutation,
} from "redux/api/Employees/employeesApi";

export default function ClientSources() {
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = React.useState(null);
  const { id } = useParams();
  let location = useLocation();
  let history = useHistory();
  const EmployeeData = useGetEmployeeNewsSourcesQuery(id);
  const [
    createEmployeeNewsSources,
    { isLoading: createLoading },
  ] = useCreateEmployeeNewsSourcesMutation();
  const { data: sources, isLoading } = useGetSourcesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
    status: true,
  });

  useEffect(() => {
    if (sources) {
      const newSelectData = sources?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions([...newSelectData]);
    }
  }, [sources]);

  React.useEffect(() => {
    let text = document.querySelector(".select-panel input");
    let selectAllText = document.querySelector(".item-renderer span");

    if (text) {
      text.placeholder = "Xəbər Mənbəsi seç...";
    }
    if (selectAllText) {
      selectAllText.innerHTML = "Hamısı";
    }
  }, [selected]);

  useEffect(() => {
    if (options && EmployeeData?.data?.data) {
      setSelected(
        EmployeeData?.data?.data
          ?.map((el) => options.find((mo) => mo?.value == el?.newsSourceId))
          .filter((item) => item !== undefined)
      );
    }
  }, [EmployeeData.data, options]);

  const onPostClientNewsSource = () => {
    const convertResult = selected?.map((item) => {
      return item?.value;
    });

    createEmployeeNewsSources({ id, newsSources: convertResult });
  };

  return (
    <div className="row bg-white rounded p-4">
      <div className="col-12 d-flex align-items-center py-4 height-65 justify-content-between">
        <button onClick={() => history.goBack()} className="handleGoBack">
          {" "}
          <KeyboardBackspaceIcon /> Geri
        </button>

        <h1 className="display-4 mb-0 m-4">
          {location?.state} xəbər mənbələri
        </h1>
        <Button
          type="submit"
          className={`px-9`}
          onClick={() => onPostClientNewsSource()}
        >
          {createLoading && (
            <span className="mr-8 spinner spinner-white"></span>
          )}
          Seçilmiş xəbər mənbələrini əlavə et
        </Button>
      </div>
      <RenderIf condition={isLoading}>
        <div className=" d-flex justify-content-center align-items-center w-100">
          <CircularProgress />
        </div>
      </RenderIf>

      <RenderIf condition={options && !isLoading}>
        <MultiSelect
          options={options || []}
          value={selected || []}
          onChange={setSelected}
          labelledBy="Xəbər Mənbəsi seç..."
          isOpen={true}
        />
      </RenderIf>

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
