import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const proxiesApi = createApi({
  reducerPath: "proxiesApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ["Proxies"],
  endpoints: (builder) => ({
    getProxies: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;

        return {
          url: `proxies?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.userName ? "&userName=" + name?.userName : ""
          }${name?.ip ? "&Ip=" + name?.ip : ""}${
            name?.port ? "&Port=" + name?.port : ""
          }${name?.FromDate ? "&FromDate=" + name?.FromDate : ""}${
            name?.ToDate ? "&ToDate=" + name?.ToDate : ""
          }`,
        };
      },
      providesTags: ["Proxies"],
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
    }),

    checkProxies: builder.mutation({
      query(id) {
        return {
          url: `proxies/${id}/check`,
          method: "POST",
        };
      },
      invalidatesTags: ["Proxies"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    checkProxiesUrl: builder.mutation({
      query(data) {
        return {
          url: `proxies/check-news-source`,
          method: "POST",
          data
        };
      },
    
    }),
    getProxiesItem: builder.query({
      query(id) {
        return {
          url: `proxies/${id}`,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
    }),
    createProxies: builder.mutation({
      query(form) {
        return {
          url: "proxies",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Proxies"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateProxies: builder.mutation({
      query(data) {
        return {
          url: "proxies",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Proxies"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    changeStatus: builder.mutation({
      query(data) {
        return {
          url: `proxies/${data?.id}/status`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Proxies"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateProxiesPassword: builder.mutation({
      query(data) {
        return {
          url: "proxies/reset-password",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteProxies: builder.mutation({
      query(id) {
        return {
          url: `proxies/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["Proxies"],
    }),
  }),
});

export const {
  useCheckProxiesUrlMutation,
  useLazyGetCheckQuery,
  useChangeStatusMutation,
  useCheckProxiesMutation,
  useGetProxiesQuery,
  useGetProxiesItemQuery,
  useUpdateProxiesMutation,
  useCreateProxiesMutation,
  useDeleteProxiesMutation,
  useUpdateProxiesPasswordMutation,
} = proxiesApi;
