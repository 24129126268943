import React, {useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "app/modules/UI/Datetimepicker";
import ReactQquill from "app/modules/UI/RichTextEditor";
import ReactSelect from "../../UI/ReactSelect";
import ReactMultiSelect from "../../UI/ReactMultiSelect";
import RenderIf from "app/modules/UI/RenderIf";
import {
  useGetCategoriesQuery,
} from "redux/api/Categories/categoriesApi";
import { useUpdateNewsMutation ,useGetNewsItemQuery} from "redux/api/News/newsApi";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";

export default function Create(props) {
  const { show, onHide, id } = props;
  const [sourceOption, setSourceOption] = React.useState(null);
  const [sourceOptions, setSourceOptions] = React.useState(null);
  const [categoryOption, setCategoryOption] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState(
    null
  );

  const initialValues = {

    newsSourceId: "",
    categoryNews: "",
    title:"",
    body:"",
    source:"",
    newsDate:"",
    created:"",
  };



  const { data: CategoriesData } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });
  const { data: NewsSourcesData } = useGetSourcesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });
  const { data } = useGetNewsItemQuery(id);

  useEffect(() => {
    if (data) {
      const { id,  title, body,source,newsDate,created} = data;
      setValues({ id, title, body,source,newsDate,created:created.split(".")[0]});

    }
  }, [data]);

  useEffect(() => {
    if (CategoriesData) {
      const newSelectData = CategoriesData?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoryOptions([...newSelectData]);
    }
  }, [CategoriesData]);

  useEffect(() => {
    if (NewsSourcesData) {
      const newSelectData = NewsSourcesData?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSourceOptions([{ label: "Heçbiri", value: 0 }, ...newSelectData]);
    }
  }, [NewsSourcesData]);

  useEffect(() => {
 if(NewsSourcesData && sourceOptions){
    const sourceOptionValue = sourceOptions?.find(
      (option) => option.value == data?.newsSourceId
    );
    setSourceOption(sourceOptionValue);
    setFieldValue("newsSourceId", sourceOptionValue?.value);
  }
  }, [NewsSourcesData, sourceOptions,data?.newsSourceId]);

  const [keywordChosen, setKeywordChosen] = React.useState([]);

  useEffect(() => {
    if (data?.categoryNews && categoryOptions) {
      data?.categoryNews?.map((item, index) => {
        keywordChosen.push(
          categoryOptions?.find(
            (option) => option.value == item.categoryId
          )
        );
        return index;
      });
      setFieldValue("categoryNews", data?.categoryNews);
      setCategoryOption((prev) => keywordChosen);
    }
  }, [data, categoryOptions]);

  function handleChangeInterest(c) {
    setCategoryOption(c);
    const mockArray = [];
    c.forEach((element) => {
      mockArray.push({categoryId:element.value});
    });

    setFieldValue("categoryNews", mockArray);
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };




  const [
    updateNews,
    { isLoading, isSuccess },
  ] = useUpdateNewsMutation();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    // name: Yup.string().required("Başlıq daxil etməyiniz tələb olunur."),
    // newsSourceId: Yup.string().required(
    //   "Üst kateqoriya daxil etməyiniz tələb olunur."
    // ),
    // categoryNews: Yup.string().required(
    //   "Açar sözlər  daxil etməyiniz tələb olunur."
    // ),
  });

  function handleChangeRole(value) {
    setSourceOption(value);
    setFieldValue("newsSourceId", value?.value);
  }

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }
    return false;
  };

  const onPostHumanResource = (values) => {
    const humanResource = {
      id: id,
     ...values
    };
    updateNews(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm, values, setFieldValue, setValues, errors } = formik;
 

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Başlıq"
                    placeholder="Başlıq"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.title && formik.errors.title}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 ">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="source"
                    name="source"
                    label="Mənbə"
                    placeholder="Mənbə"
                    className="w-100"
                    error={getInputClasses("source")}
                    {...formik.getFieldProps("source")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.source && formik.errors.source}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.source}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 ">
          
                  
                  <ReactQquill 
                    //  value={textBody }
                     error={getInputClasses("body")}
                     {...formik.getFieldProps("body")}
                     onChange={(value) =>{
                      formik.setFieldValue("body", value); 
                    }}
                  />
            
                <RenderIf condition={formik.touched.source && formik.errors.source}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.source}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={sourceOptions}
                    handleChangeSingle={(value) => handleChangeRole(value)}
                    single={sourceOption}
                    label="Xəbər mənbəsi"
                    placeholder="Xəbər mənbəsi"
                    inputId="parentCategoryId"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.parentCategoryId &&
                    formik.errors.parentCategoryId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.parentCategoryId}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactMultiSelect
                    isMulti={true}
                    suggestions={categoryOptions}
                    handleChangeSingle={handleChangeInterest}
                    single={categoryOption}
                    label="Xəbər kategoriyasi"
                    placeholder="Xəbər kategoriyasi"
                    inputId="id"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.searchKeywords &&
                    formik.errors.searchKeywords
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.searchKeywords}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-6 col-lg-6 Date_Picker_Wrapper">
           
                <DatePicker
                  error={getInputClasses("newsDate")}
                  {...formik.getFieldProps("newsDate")}
                  label="Xəbər tarixi"
                  onChange={(e) =>{
                    formik.setFieldValue("newsDate", e.target.value); 
                  }}
                />
          
            </div>

            <div className="col-12 col-md-6 col-lg-6 Date_Picker_Wrapper">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                error={getInputClasses("created")}
                {...formik.getFieldProps("created")}
                  label="Yaradılma tarixi"
                  onChange={(e) =>{
                    formik.setFieldValue("created", e.target.value); 
                  }}
                />
              </div>
            </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
             
            }}
          >
            Bağla
          </Button>
          <Button type="submit" className={`px-9`}>
            Düzəliş et
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
