import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function Preview(props) {
  const { preview, fileName, onClick } = props;

  if (preview) {
    return (
      <>
        <div className="image-preview file-preview d-flex align-items-center ">
          <p className="m-0 mr-3">{fileName}</p>
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
            onClick={onClick}
            className={`${fileName ? "d-block" : "d-none"}`}
          />
        </div>
      </>
    );
  }
  return <div></div>;
}
