import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import ReactSelect from "app/modules/UI/ReactSelect";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage,
  } = props;
  const history = useHistory();

  const [roleOption, setRoleOption] = React.useState({
    value: false,
    label: "Mövcud",
  });

  const rolesOptions = [
    { value: "", label: "Hamısı" },
    { value: false, label: "Mövcud" },
    { value: true, label: "Silinən" },
  ];

  const updateUrl = (newFilterInputs) => {
    const params = new URLSearchParams(newFilterInputs).toString();
    history.push(`?${params}`);
  };

  const handleFilterChange = (e) => {
    const updatedFilters = { ...filterInputs, [e.target.name]: e.target.value };
    setFilterInputs(updatedFilters);
    filterInputsUpdate(e);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeRole = (value) => {
    const updatedFilters = { ...filterInputs, deleted: value?.value };
    setRoleOption(value);
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const onResetFilter = () => {
    const resetFilters = {
      Username: "",
      Name: "",
      deleted: false,
    };
    setFilterInputs(resetFilters);
    updateUrl(resetFilters);
  };

  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-right pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create"> Müştərilər filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="Name"
                  name="Name"
                  label="Ad"
                  placeholder="Ada  görə axtarış"
                  className="w-100"
                  value={filterInputs.Name}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="Username"
                  name="Username"
                  label="İstifadəçi adı"
                  placeholder="İstifadəçi adına görə axtarış"
                  className="w-100"
                  value={filterInputs.Username}
                  onChange={handleFilterChange}
                />
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                  suggestions={rolesOptions}
                  handleChangeSingle={handleChangeRole}
                  single={roleOption}
                  label="Status"
                  placeholder="Status"
                  inputId="roleId"
                />
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-12"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onResetFilter}>
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
