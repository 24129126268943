import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField/index";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useUpdateProxiesPasswordMutation } from "redux/api/Proxies/proxiesApi";
import { useFormik } from "formik";
import * as Yup from "yup";


const initialValues = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export default function ChangePassword(props) {
  const { onHide, id, show,  } = props;


  const [values, setValues] = useState({
    oldPassword: " ",
    newPassword: "",
    newPasswordConfirm: "",
    showOldPassword: false,
    showNewPassword: false,
    showRePassword: false,
  });

  const [
    updateProxiesPassword,
    { isLoading, error, isSuccess, status },
  ] = useUpdateProxiesPasswordMutation();

  useEffect(() => {
    if (isSuccess) {

      onHide();
    }
  }, [isLoading]);

  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };
  const PasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
  .required("Yeni Şifrə daxil etməyiniz tələb olunur"),
    newPasswordConfirm: Yup.string()
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Hər iki şifrə eyni olmalıdır"
        ),
      })
      .required("Yeni Şifrəni təkrar daxil etməyiniz tələb olunur"),
  });

  const onChangeUserPassword = (values, resetForm, setSubmitting) => {
    const changeUser = {
      id: id,
      password: values.newPassword,
      confirmPassword: values.newPasswordConfirm,
    };
    updateProxiesPassword(changeUser);
  };

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onChangeUserPassword(values, resetForm, setSubmitting);
      resetForm();
    },
  });

  return (
    <div>
      <Modal
        onHide={onHide}
        show={show}
        size="sm"
        aria-labelledby="edit"
        centered
        className="modal-center pr-0"
      >
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="changePassword">Şifrəni dəyiş</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 pl-4 pr-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="newPassword"
                      className="w-100"
                      type={values.showNewPassword ? "text" : "password"}
                      label="Yeni Şifrə"
                      // value={values.confirmPassword}
                      error={getInputClasses("newPassword")}
                      {...formik.getFieldProps("newPassword")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("showNewPassword")
                              }
                            >
                              {values.showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.newPassword}
                      </div>
                    </div>
                  ) : null}
               
                </div>

                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="newPasswordConfirm"
                      className="w-100"
                      type={values.showRePassword ? "text" : "password"}
                      label="Təkrar Yeni Şifrə"
                      // value={values.newPassword}
                      error={getInputClasses("newPasswordConfirm")}
                      {...formik.getFieldProps("newPasswordConfirm")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("showRePassword")
                              }
                            >
                              {values.showRePassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {formik.touched.newPasswordConfirm &&
                  formik.errors.newPasswordConfirm ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.newPasswordConfirm}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => {
                onHide(false);
                formik.resetForm();
              }}
            >
              Bağla
            </Button>
            <Button type="submit"  className={`px-9`}>
              Yadda saxla
              {isLoading && <span className="ml-3 spinner spinner-white"></span>}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
