import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { setRoles } from "../../features/Role/RoleSlicer";

export const rolesApi = createApi({
  reducerPath: "rolesApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getRoles: builder.query({
      query(filter) {
        return {
          url: `role`,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setRoles(data));
        } catch (error) {}
      },
    }),

    getRole: builder.query({
      query(id) {
        return {
          url: `role/id?id=${id}`,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createRoles: builder.mutation({
      query(form) {
        return {
          url: "role",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateRoles: builder.mutation({
      query(data) {
        return {
          url: "role",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteRoles: builder.mutation({
      query(id) {
        return {
          url: `role`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useCreateRolesMutation,
  useDeleteRolesMutation,
  useUpdateRolesMutation,
} = rolesApi;
