import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useUpdateProxiesMutation,
  useGetProxiesItemQuery,
} from "redux/api/Proxies/proxiesApi";

export default function Create(props) {
  const { show, onHide, id } = props;
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { data } = useGetProxiesItemQuery(id);

  const initialValues = {
    ip: "",
    port: "",
    username: "",
    password: "",
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (data) {
      const { id, port, username, ip, password } = data;
      setValues({ id, port, username, ip, password });
    }
  }, [data]);

  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const [
    updateProxies,
    { isLoading, error, isSuccess },
  ] = useUpdateProxiesMutation();
  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    username: Yup.string().required(
      "İstifadəçi adı daxil etməyiniz tələb olunur."
    ),
    ip: Yup.string().required("Ip daxil etməyiniz tələb olunur."),
    port: Yup.string().required("Port daxil etməyiniz tələb olunur."),


  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostHumanResource = (values) => {
    const humanResource = {
      id,
      ip: values.ip,
      port: values.port,
      username: values?.username,
    };

    updateProxies(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { setValues, resetForm, values } = formik;

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="ip"
                    name="ip"
                    label="İp"
                    placeholder="İp"
                    className="w-100"
                    error={getInputClasses("ip")}
                    {...formik.getFieldProps("ip")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.ip && formik.errors.ip ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.ip}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="port"
                    label="Port"
                    placeholder="Port"
                    className="w-100"
                    error={getInputClasses("port")}
                    {...formik.getFieldProps("port")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.port && formik.errors.port ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.port}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="username"
                    label="İstifadəçi adı"
                    placeholder="İstifadəçi adı"
                    className="w-100"
                    error={getInputClasses("username")}
                    {...formik.getFieldProps("username")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.username && formik.errors.username ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.username}
                    </div>
                  </div>
                ) : null}
              </div>

      
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Düzəliş et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
