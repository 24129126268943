import React from "react";
import IconButton from "@material-ui/core/IconButton";
import TablePagination from "@material-ui/core/TablePagination";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory, useLocation } from "react-router-dom";

const CustomPagination = ({
  page,
  rowsPerPage,
  setRowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
}) => {
  const handleFirstPageButtonClick = (event) => {
    handleChangePage(event, 0);
  };

  const handleLastPageButtonClick = (event) => {
    handleChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleBackButtonClick = (event) => {
    if (page > 0) {
      handleChangePage(event, page - 1);
    }
  };

  const handleNextButtonClick = (event) => {
    if (page < Math.ceil(count / rowsPerPage) - 1) {
      handleChangePage(event, page + 1);
    }
  };
  const location = useLocation();
  const history = useHistory();
  const updateURLParams = (newParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(newParams).forEach((key) => {
      searchParams.set(key, newParams[key]);
    });
    history.push({ search: searchParams.toString() });
  };

  React.useEffect(() => {
    if (rowsPerPage > count || ![5, 10, 25].includes(rowsPerPage)) {
      setRowsPerPage(count);
    
      updateURLParams({ page: page, rowsPerPage: count });
    }
  }, [count, rowsPerPage, setRowsPerPage, ]);
  
  

  return (
    <div className="d-flex justify-content-end">
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        <ArrowBackIosIcon />
      </IconButton>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { value: count, label: "All" }]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        labelRowsPerPage="Sətir sayı:"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export default CustomPagination;
