import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  width: "100%",
}));

export default function DateAndTimePickers({ label, onChange, value }) {
  const classes = useStyles();
  const formatDateToDDMMYYYY = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };
  return (
    <div className="w-100 date_wrapper">
      <TextField
        id="datetime-local"
        label={label}
        value={value}
        onChange={onChange}
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="date"
        className="date_text_input"
        type="text"
        placeholder="dd/mm/yyyy"
        value={formatDateToDDMMYYYY(value)}
      />
    </div>
  );
}
