import {createSlice} from "@reduxjs/toolkit"




const initialState= {
    news: [],
  
}

export const newsSlice = createSlice({
    initialState,
    name: "newsSlice",
    reducers: {
        setNews: (state,action) => {
            state.news =action.payload
        },
      
    }
})

export default newsSlice.reducer;
export const {setNews} = newsSlice.actions