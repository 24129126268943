import React from "react";

const TableCheck = ({ rows }) => {
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return (
    <div>
      <div className="container news_source_check_table">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Başlıq</th>
                <th>Url</th>
                <th>Tarix</th>
              </tr>
            </thead>
            <tbody>
              {rows?.map((row, index) => (
                <tr key={index}>
                  <td>{row?.title}</td>
                  <td>
                    <a href={row?.url} target="_blank" rel="noopener noreferrer">
                      {row?.url}
                    </a>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {formatDate(row?.newsDate)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableCheck;
