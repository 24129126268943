import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDeleteNotificationAllMutation } from "redux/api/NewsSources/newsSourcesApi";
import { useSelector, useDispatch } from "react-redux";
import { setErrorCount } from "redux/features/NewsSources/newsSourcesSlices";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100% !important",
    height: "100px !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

export default function RemoveAll(props) {
  const dispatch = useDispatch();
  const errorCount = useSelector((state) => state.sources.errorCount);
  const { onHide, show, deleteUsersIds, setDeleteUsersIds, refetch } = props;

  const [
    deleteNotificationAll,
    { isLoading, error, isSuccess },
  ] = useDeleteNotificationAllMutation();

  useEffect(() => {
    if (isSuccess) {
      if (deleteUsersIds?.length) {
        dispatch(setErrorCount(errorCount - deleteUsersIds?.length));
      } else {
        dispatch(setErrorCount(0));
      }

      setDeleteUsersIds([]);
      onHide();
    }
  }, [isLoading]);

  const onDeleteUser = (e) => {
    e.preventDefault();

    deleteNotificationAll(deleteUsersIds);
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteUser}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4 mb-3">
            <div className="row">Silmək istədiyinizdən əminsinizmi?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" variant="danger">
            Sil
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
