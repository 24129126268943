import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import CustomPagination from "app/modules/UI/CustomPagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import PlusIcon from "@material-ui/icons/Add";
import { GrPowerReset } from "react-icons/gr";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import StatusButton from "../../../../_metronic/_helpers/StatusButton";
import RenderIf from "app/modules/UI/RenderIf";

const headRows = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "Ad",
  },

  {
    id: "helperwords",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },

  { id: "tools", numeric: false, disablePadding: true, label: "Əməliyyatlar" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    Permissions_Users_Delete,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="left"
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? false : false}
          >
            {row.id == "tools" ? (
              row.label
            ) : (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order ? "asc" : "desc"}
                onClick={() => {
                  return row.id !== "tools" ? onRequestSort(row.id) : null;
                }}
              >
                {row.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.bool,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    flexWrap: "wrap",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  label: {
    fontSize: ".875rem",
    width: "100%",
  },
  toolbar: {
    zIndex: "10",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    setOpenCreate,
    setOpenRemoveAll,
    setFilterInputs,
    City_Add,
    tableHeaderRef,
    refetch,
  } = props;

  const onResetFilter = () => {
    setFilterInputs({
      firstName: "",
      lastName: "",
      email: "",
      roles: "",
    });
    setRolesOption([]);
  };

  const [rolesOption, setRolesOption] = React.useState([]);
  const handleRefetch = async () => {
    try {
      await refetch();
      toast.success("Məlumatlar uğurla yeniləndi!");
    } catch (error) {
      toast.error("Yeniləmə zamanı səhv baş verdi!");
    }
  };
  return (
    <Toolbar
      className={clsx(classes.root, classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
      ref={tableHeaderRef}
    >
      <div className="d-flex w-100">
        <div className={classes.title}></div>
        <div className={classes.spacer} />
        <button className="reset_wrapper mr-2 mt-1" onClick={handleRefetch}>
          <GrPowerReset />
        </button>
        <div className={classes.actions}>
          <RenderIf condition={numSelected > 0}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={() => {
                  setOpenRemoveAll(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </RenderIf>
        </div>

        <Tooltip title="Əlavə et" placement="top">
          <button
            className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary mr-2 h5 rounded-circle flex-shrink-0"
            onClick={() => setOpenCreate(true)}
          >
            <PlusIcon />
          </button>
        </Tooltip>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  height: {
    height: "calc(100% - 65px)",
  },
  root: {
    width: "100%",
    height: "100%",
    padding: "0px",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  toolbar: {
    minHeight: theme.spacing(2),
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export default function EnhancedTable(props) {
  const {
    setSortField,
    setOrderByTable,
    sortField,
    orderBy,
    setOpenEdit,
    setOpenView,
    setOpenRemove,
    setOpenRemoveAll,
    setOpenCreate,
    isLoaded,
    error,
    rows,
    updateSkip,
    updateTake,
    permissions,
    count,
    setProxiesId,
    setDeleteUsersID,
    deleteUsersIds,
    onDeleteUsers,
    setPage,
    setRowsPerPage,
    onUpdateUserStatus,
    page,
    rowsPerPage,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    filterStatusesUpdate,
    handleChangeRowsPerPage,
    handleChangePage,
    refetch,
  } = props;
  const classes = useStyles();

  function handleRequestSort(property) {
    const isDesc = sortField === property && !orderBy;
    setRowsPerPage(10);
    setPage(0);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);

      setDeleteUsersID(newSelecteds);
      return;
    }

    setDeleteUsersID([]);
  }

  function handleClick(event, id) {
    const selectedIndex = deleteUsersIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(deleteUsersIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(deleteUsersIds.slice(1));
    } else if (selectedIndex === deleteUsersIds.length - 1) {
      newSelected = newSelected.concat(deleteUsersIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        deleteUsersIds.slice(0, selectedIndex),
        deleteUsersIds.slice(selectedIndex + 1)
      );
    }

    setDeleteUsersID(newSelected);
  }

  const isSelected = (id) => deleteUsersIds.indexOf(id) !== -1;

  const { pathname } = useLocation();

  const content = useRef(null);
  const tableHeader = useRef(null);
  const tableWrapperHeader = useRef(null);

  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);

  useEffect(() => {
    let paperHeigth = content?.current?.offsetHeight;
    let tableHeaderHeight = tableHeader?.current?.offsetHeight;
    setTableWrapperHeight(paperHeigth - tableHeaderHeight - 52);
  }, [pathname]);

  return (
    <div className={`col-12 ${classes.height}`}>
      <div className={classes.root}>
        <Paper className={classes.paper} ref={content}>
          {isLoaded && !rows.length && (
            <div className={classes.progresRoot}>
              <CircularProgress className={classes.progress} />
            </div>
          )}

          <EnhancedTableToolbar
            numSelected={deleteUsersIds.length}
            setOpenCreate={setOpenCreate}
            setOpenRemoveAll={setOpenRemoveAll}
            filterInputsUpdate={filterInputsUpdate}
            filterInputs={filterInputs}
            setFilterInputs={setFilterInputs}
            filterStatusesUpdate={filterStatusesUpdate}
            tableHeaderRef={tableHeader}
            refetch={refetch}
          />
          <div
            className={classes.tableWrapper}
            ref={tableWrapperHeader}
            style={{ maxHeight: tableWrapperHeight + "px" }}
          >
            <RenderIf condition={rows.length}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <EnhancedTableHead
                  numSelected={deleteUsersIds.length}
                  order={orderBy}
                  orderBy={sortField}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.value}
                        </TableCell>

                        <TableCell padding="none" className="pr-4 text-center">
                          <StatusButton
                            id={row.id}
                            status={row.status}
                            onUpdateUserStatus={onUpdateUserStatus}
                          />
                        </TableCell>
                        <TableCell
                          padding="none"
                          className="pr-4"
                          align="right"
                        >
                          <Tooltip title="Hamısını göstər" placement="top">
                            <button
                              aria-label="view"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                              onClick={(e) => {
                                setOpenView(e);
                                setProxiesId(row.id);
                              }}
                            >
                              <EyeIcon />
                            </button>
                          </Tooltip>

                          <Tooltip title="Düzəliş et" placement="top">
                            <button
                              aria-label="edit"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                              onClick={() => {
                                setOpenEdit(true);
                                setProxiesId(row.id);
                              }}
                            >
                              <EditIcon />
                            </button>
                          </Tooltip>

                          <Tooltip title="Sil" placement="top">
                            <button
                              aria-label="Delete"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                              onClick={() => {
                                setOpenRemove(true);
                                setProxiesId(row.id);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </RenderIf>

            <RenderIf condition={!rows.length && !isLoaded && !error}>
              <AlertComponent
                variant="info"
                message="Heç bir məlumat tapılmadı"
              />
            </RenderIf>
            <RenderIf condition={!isLoaded && error}>
              <AlertComponent
                variant="danger"
                message="We've lost the connection"
              />
            </RenderIf>
          </div>
          <RenderIf condition={count > 10}>
            <CustomPagination
              count={count}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </RenderIf>
        </Paper>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
