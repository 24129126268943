import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactSelect from "../../UI/ReactSelect";
import PlusIcon from "@material-ui/icons/Add";

const ParticipantInformation = ({

  getFieldProps,
  values,
  touched,
  dateFormatString,
  errors,
  addItem,
  handleRemoveItem,
  singleValue,
  handleChangeOffset,
  OffsetType
}) => {


  return (
    <>
      {values?.scrapeDateFormats?.map((_, index) => {
        const scrapeDateFormatsError =
          (errors.scrapeDateFormats?.length &&
            errors.scrapeDateFormats[index]) ||
          {};
        const scrapeDateFormatsTouch =
          (touched.scrapeDateFormats?.length &&
            touched.scrapeDateFormats[index]) ||
          {};

        return (
          <div className="row  w-100 pl-4 pr-4">
            <div className="col-6 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-4 d-flex flex-wrap">
                <TextField
                  id="dateFormat1"
                  name="dateFormat1"
                  label={`${dateFormatString} ${index + 1}`}
                  placeholder={`${dateFormatString} ${index + 1}`}
                  className="w-100"
                  {...getFieldProps(
                    `scrapeDateFormats[${index}].dateFormat`
                  )}
                />
                {scrapeDateFormatsTouch.dateFormat &&
                scrapeDateFormatsError.dateFormat ? (
                  <div className="mt-2">
                    <div className="text-danger">
                      {scrapeDateFormatsError["dateFormat"]
                        ? scrapeDateFormatsError["dateFormat"]
                        : scrapeDateFormatsError["dateFormat"]}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-5 col-md-5 col-lg-5">
                <div className="w-100 pt-2 pb-4 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={OffsetType}
                    handleChangeSingle={(value) =>
                      handleChangeOffset(value, index)
                    }
                    single={
                      Array.isArray(singleValue) && singleValue.length > index
                        ? singleValue[index]
                        : undefined
                    }
                    label={`Offset Type `}
                    placeholder={`Offset Type ${index + 1}`}
                    inputId="offsetType"
                    isClearable={true}
                  />
                </div>
              </div>
          
            <div className="col col-1">
              {index > 0 && (
                <span
                  aria-label="Delete"
                  className="btn mt-5 btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                  onClick={() => {
                    handleRemoveItem(index);
                  }}
                >
                  <DeleteIcon />
                </span>
              )}
            </div>
          </div>
        );
      })}
      {/* {values?.scrapeDateFormats?.length <= 3 && ( */}
        <div className="col col-6 d-flex align-items-end mt-4 p-0">
          <Button onClick={addItem} className={`px-3`}>
            <PlusIcon /> Date format əlavə et
          </Button>
        </div>
      {/* )} */}
    </>
  );
};

export default ParticipantInformation;
