import {createSlice} from "@reduxjs/toolkit"




const initialState= {
    categories: [],
  
}

export const categoriesSlice = createSlice({
    initialState,
    name: "categoriesSlice",
    reducers: {
        setCategories: (state,action) => {
            state.categories =action.payload
        },
      
    }
})

export default categoriesSlice.reducer;
export const {setCategories} = categoriesSlice.actions