import React, { useState, useEffect } from "react";
import DataTables from "./components/KeywordTables";
import { useHistory } from "react-router-dom";
import View from "./modals/View";
import Create from "./modals/Create";
import Delete from "./modals/Remove";
import Filter from "./modals/Filter";
import Edit from "./modals/Edit";
import Switch from "@material-ui/core/Switch/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import { updateObject } from "../../../Helpers/updateObject";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import { ToastContainer } from "react-toastify";
import { useGetKeywordsQuery } from "../../../redux/api/Searchkeyword/searchKeywordApi";
import { useSelector } from "react-redux";
import RenderIf from "../UI/RenderIf";

export function SearchKeyword() {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const permissions = user?.permissions;
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = React.useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const handleChange = () => {
    setShowFilter(!showFilter);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);

  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = React.useState(parseInt(params.get("page"), 10) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(params.get("rowsPerPage"), 10) || 10
  );
  const [keywordId, setKeywordId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [filterInputs, setFilterInputs] = useState({
    SearchTerm: "",
    expression: "",
    description: "",
    address: "",
    email: "",
    deleted: false,
    FromDate: "",
    ToDate: "",
  });

  const { data, isLoading, refetch } = useGetKeywordsQuery({
    skip: page * rowsPerPage,
    take: rowsPerPage,
    field: "id",
    orderBy: false,
    name: filterInputs,
  });
  function handleChangePage(event, newPage) {
    const newUrl = `?page=${newPage}&rowsPerPage=${rowsPerPage}`;
    history.replace(newUrl);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    history.push(`?page=0&rowsPerPage=${newRowsPerPage}`);
  }
  const filterInputsUpdate = (e) => {
    setPage(0);
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });
    setFilterInputs(updateFiltersInput);
  };
  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });

    setFilterInputs(updateFiltersInput);
  };

  return (
    <div className="row bg-white rounded d-flex  justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className=" mb-0">Axtarış Açar Sözləri</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Filtri gizlət" : "Filtri göstər"}
          className="ml-auto mb-0"
        />
      </div>
      <RenderIf condition={isLoading}>
        <div>
          <CircularProgress />
        </div>
      </RenderIf>
      <RenderIf condition={data && !isLoading}>
        <DataTables
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenCreate={setShowCreate}
          rows={data?.data}
          count={data?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          permissions={permissions}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          setKeywordId={setKeywordId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
        />
      </RenderIf>

      <RenderIf condition={showView}>
        <View open={showView} onClose={toggleDrawer(false)} id={keywordId} />
      </RenderIf>
      <RenderIf condition={showCreate}>
        <Create
          show={showCreate}
          onHide={setShowCreate}
          refetch={refetch}
          filterInputs={filterInputs}
        />
      </RenderIf>
      {/* <RenderIf condition={showEdit}> */}
      {showEdit && (
        <Edit
          id={keywordId}
          show={showEdit}
          onHide={setShowEdit}
          refetch={refetch}
          filterInputs={filterInputs}
        />
      )}

      {/* </RenderIf> */}
      <RenderIf condition={showRemove}>
        <Delete
          id={keywordId}
          show={showRemove}
          onHide={setShowRemove}
          refetch={refetch}
          filterInputs={filterInputs}
        />
      </RenderIf>

      <Filter
        setPage={setPage}
        show={showFilter}
        onHide={handleChange}
        filterInputsUpdate={filterInputsUpdate}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
      />
       <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
