import {createSlice} from "@reduxjs/toolkit"


const initialState = {
  employees: [],
  employee: [],

}

export const employeesSlice = createSlice({
  initialState,
  name: "employeesSlice",

  reducers: {
    setEmployees: (state,action) => {
      state.employees  = action.payload
    },
    setEmployee: (state,action) => {
      state.employee  = action.payload
    },

  }
})

export default employeesSlice.reducer;
export const {setEmployees} = employeesSlice.actions
