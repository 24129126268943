import React from "react";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import Tooltip from "@material-ui/core/Tooltip/index";

export default function PowerButton({ id, status, onUpdateUserStatus }) {
  return (
    <Tooltip title={status ? "İnaktiv et" : "Aktivləşdir"} placement="top">
      <button
        aria-label={status ? "Deactivate" : "Activate"}
        className={`btn btn-sm btn-icon btn-bg-light ${
          status ? "btn-text-success" : "btn-text-danger"
        }`}
        onClick={() => onUpdateUserStatus(id, status)}
      >
        <PowerIcon />
      </button>
    </Tooltip>
  );
}
