import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 roles:[],
 permissions2 : [],
 role:[]
}

export const roleSlicer = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoles : (state,action)=>{
      state.roles = action.payload
    },
    setRole : (state,action)=>{
      state.role = action.payload
    },
    addPermission : (state,action)=>{
   let index = state.permissions2.findIndex((element)=>element.id===action.payload.id) // 1

   if(index>=0){
    const newArray = state.permissions2[index] = action.payload
    state.permissions2 = state.permissions2.map((item) => item.id === newArray.id ? newArray : item)
   }else{
    state.permissions2 = [...state.permissions2,action.payload]
   }
     },
     
   removePermission: (state,action) =>{
    state.permissions2= state.permissions2.filter(el=>el.id != action.payload.id)
   },
   resetPermission: (state,action) =>{
    state.permissions2=[];
   }

  },
})

// Action creators are generated for each case reducer function
export const { addPermission,setRoles,setRole,removePermission,resetPermission} = roleSlicer.actions

export default roleSlicer.reducer