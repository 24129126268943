import React, { useEffect, useState } from "react";
import { Modal, Button, Alert, Spinner } from "react-bootstrap";
import ReactSelect from "../../UI/ReactSelect";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import { useCheckProxiesUrlMutation } from "redux/api/Proxies/proxiesApi";

export default function Create(props) {
  const { show, onHide, id } = props;
  const [sourceOption, setSourceOption] = useState(null);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [proxyMessage, setProxyMessage] = useState("");

  // Fetch sources
  const { data } = useGetSourcesQuery({
    skip: 0,
    take: "",
    field: "id",
    orderBy: false,
  });

  useEffect(() => {
    if (data) {
      const newSelectData = data.data.map((item) => ({
        value: item.id,
        label: item.url,
      }));
      setSourceOptions(newSelectData);
    }
  }, [data]);

  // Mutation hook for checking proxies
  const [checkProxiesUrl, { isLoading, data: checkData, isSuccess }] = useCheckProxiesUrlMutation();

  const handleChange = (value) => {
    setSourceOption(value);
    if (value) {
      const sendData = {
        id,
        newsSourceId: value.value,
      };
      checkProxiesUrl(sendData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (checkData) {
        setProxyMessage("Proksi uyğundur.");
      } else {
        setProxyMessage("Proksi uyğun deyil.");
      }
    }
  }, [isSuccess, checkData]);

  useEffect(() => {
    setProxyMessage("")
    setSourceOption(null)
  }, [show]);
  return (
    <Modal
      size="md"
      aria-labelledby="create"
      centered
      className="modal-center pr-0 check_url_modal"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="create"></Modal.Title>
      </Modal.Header>
      <Modal.Body id="userModal">
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <ReactSelect
                suggestions={sourceOptions}
                handleChangeSingle={handleChange}
                single={sourceOption}
                label="Xəbər mənbəsi"
                placeholder="Xəbər mənbəsi"
                inputId="parentCategoryId"
                isClearable
                disabled={isLoading}
              />
              {isLoading ? (
                 <div className="d-flex justify-content-center w-100">
                  <Spinner animation="border" variant="primary" className="mt-4 spinner_check_url" />
                 </div>

              ) : (
                proxyMessage && sourceOption && (
                  <Alert variant={checkData ? "success" : "danger"} className="mt-4">
                    {proxyMessage}
                  </Alert>
                )
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => onHide(false)}>
          Bağla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
