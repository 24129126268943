import React from "react";
import { Button } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import PlusIcon from "@material-ui/icons/Add";
import moment from "moment";
import TimePicker from "rc-time-picker";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import "rc-time-picker/assets/index.css";


const ParticipantInformation = ({
  values,
  touched,
  handleChangeHour,
  errors,
  addHour,
  handleRemoveHour,
}) => {

  return (
    <div>
      {values?.daysWeekMailHours?.map((_, index, key) => {
        const clientMailHoursError =
          (errors.daysWeekMailHours?.length &&
            errors.daysWeekMailHours[index]) ||
          {};
        const clientMailHoursTouch =
          (touched.daysWeekMailHours?.length &&
            touched.daysWeekMailHours[index]) ||
          {};
        const outputArray = values?.daysWeekMailHours.map((item) => item.hour);

        return (
          <div className="row mt-8">
            <div className="col col-11 client_email_hour_input">
              {" "}
              <TimePicker
                placeholder="Email göndəriş saatı seçin... "
                showSecond={false}
                onChange={(value) => handleChangeHour(value, index)}
                value={
                  Array.isArray(outputArray) &&
                  outputArray.length > index &&
                  outputArray[index]
                    ? moment(`${outputArray[index]}`, "HH:mm")
                    : null
                }
              />
              <AccessTimeIcon />
            </div>

            <div className="col col-1">
              <span
                aria-label="Delete"
                className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                onClick={() => {
                  handleRemoveHour(index);
                }}
              >
                <DeleteIcon />
              </span>
            </div>

            {clientMailHoursTouch.hour && clientMailHoursError.hour ? (
              <div className="mt-2 ml-2">
                <div className="text-danger">
                  {clientMailHoursError["hour"]
                    ? clientMailHoursError["hour"]
                    : clientMailHoursError["hour"]}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}

      <div className="col col-6 d-flex align-items-end mt-4 p-0">
        <Button onClick={addHour} className={`px-3`} type="button">
          <PlusIcon /> Saat əlavə et
        </Button>
      </div>
    </div>
  );
};

export default ParticipantInformation;
