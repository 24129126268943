import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDeleteRolesMutation } from "../../../../redux/api/Roles/rolesApi";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100% !important",
    height: "100px !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

const initialValues = {
  deleteReason: ""
};

export default function Remove(props) {
  const classes = useStyles();
  const { onHide, show, id,refetch,  skip, take, sortField, orderBy, onFilter, filterInputs } = props;



   const [deleteRoles, { isLoading, error, isSuccess }] =
   useDeleteRolesMutation();
  
   
  useEffect(() => {
    if (isSuccess) {
      refetch()
      onHide();
    }
   
    }, [isLoading]);
 

  const onDeleteUser = (e) => {
    e.preventDefault();
    deleteRoles(id)

  };



 
      return (
      <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
        <form onSubmit={onDeleteUser}>
          <Modal.Header closeButton>
            <Modal.Title id="delete">Sil</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 pl-4 pr-4">
              <div className="row">Silməyinizə əminsiniz?</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => onHide(false)}>
              Ləğv
            </Button>
            <Button type="submit">Sil</Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  
}
