import React from "react";
import { Modal, Button } from "react-bootstrap";
import ReactSelect from "app/modules/UI/ReactSelect";
import DatePicker from "app/modules/UI/Datetimepicker";
import ReactMultiSelect from "app/modules/UI/ReactMultiSelect";
import TextField from "@material-ui/core/TextField/index";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import { useGetCategoriesQuery } from "redux/api/Categories/categoriesApi";
export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage,
  } = props;

  const { data } = useGetSourcesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });
  const { data: categoryData } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  const [roleOption, setRoleOption] = React.useState({
    value: false,
    label: "Mövcud",
  });

  const newsSurceOptions = React.useMemo(() => {
    return data?.data?.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  }, [data]);
  const categoryOptions = React.useMemo(() => {
    return categoryData?.data?.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  }, [categoryData]);

  const onResetFilter = () => {
    setFilterInputs({
      title: "",
      deleted: false,
      FromDate: "",
      ToDate: "",
      FromNewsDate:"",
      ToNewsDate:"",
      NewsSources: [],
      Categories:[]
    });
  };

  const rolesOptions = [
    { value: null, label: "Hamısı" },
    { value: false, label: "Mövcud" },
    { value: true, label: "Silinən" },
  ];

  function handleChangeRole(value) {
    setPage(0);
    setRoleOption(value);
    setFilterInputs({
      ...filterInputs,
      deleted: value?.value,
    });
  }

  function handleChangeStartDate(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      FromDate: e.target.value,
    });
  }

  function handleChangeEndDate(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      ToDate: e.target.value,
    });
  }
  function handleChangeStartDateNews(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      FromNewsDate: e.target.value,
    });
  }

  function handleChangeEndDateNews(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      ToNewsDate: e.target.value,
    });
  }
  function handleChangeNewsSource(selectedOptions) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      NewsSources: selectedOptions,
    });
  }
  function handleChangeCategory(selectedOptions) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      Categories: selectedOptions,
    });
  }
  

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Xəbərlər filtri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="title"
                  name="title"
                  label="Başlıq"
                  placeholder="Başlıq"
                  className="w-100"
                  value={filterInputs.title}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                  suggestions={rolesOptions}
                  handleChangeSingle={(value) => handleChangeRole(value)}
                  single={roleOption}
                  label="Status"
                  placeholder="Status"
                  inputId="roleId"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactMultiSelect
                  isMulti={true}
                  suggestions={newsSurceOptions}
                  handleChangeSingle={handleChangeNewsSource}
                  single={filterInputs.NewsSources}
                  label="Xəbər mənbələri"
                  placeholder="Xəbər mənbələri"
                  inputId="id"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactMultiSelect
                  isMulti={true}
                  suggestions={categoryOptions}
                  handleChangeSingle={handleChangeCategory}
                  single={filterInputs.Categories}
                  label="Kateqoriyalar"
                  placeholder="Kateqoriyalar"
                  inputId="id"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <h5>Əlavə edilmə tarixi</h5>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.FromDate}
                  label="Başlanğıc tarix"
                  onChange={(e) => handleChangeStartDate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.ToDate}
                  label="Son tarix"
                  onChange={(e) => handleChangeEndDate(e)}
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <h5>Xəbər tarixi</h5>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.FromNewsDate}
                  label="Başlanğıc tarix"
                  onChange={(e) => handleChangeStartDateNews(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.ToNewsDate}
                  label="Son tarix"
                  onChange={(e) => handleChangeEndDateNews(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onResetFilter();
          }}
        >
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
