import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DatePicker from "app/modules/UI/Datetimepicker";
import ReactSelect from "app/modules/UI/ReactSelect";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage,
  } = props;

  const history = useHistory();
  const statusOptions = [
    { value: null, label: "Hamısı" },
    { value: true, label: "Aktiv" },
    { value: false, label: "Deaktiv" },
  ];

  const [statusOption, setStatusOption] = React.useState(null);

  useEffect(() => {
    if (filterInputs) {
      if (filterInputs?.status == "true") setStatusOption(statusOptions[1]);
      if (filterInputs?.status == "false") setStatusOption(statusOptions[2]);
      if (filterInputs?.status == "null" || !filterInputs?.status ) setStatusOption(statusOptions[0]);

    }
  }, [show]);

  const updateUrl = (newFilterInputs) => {
    const params = new URLSearchParams();
    Object.keys(newFilterInputs).forEach((key) => {
      const value = newFilterInputs[key];
      if (value !== null && value !== "") {
        params.append(key, value);
      }
    });
    history.push(`?${params.toString()}`);
  };
  
  const handleFilterChange = (e) => {
    const updatedFilters = { ...filterInputs, [e.target.name]: e.target.value };
    setFilterInputs(updatedFilters);
    filterInputsUpdate(e);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeStartDate = (e) => {
    const updatedFilters = { ...filterInputs, FromDate: e.target.value };
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeEndDate = (e) => {
    const updatedFilters = { ...filterInputs, ToDate: e.target.value };
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeStatus = (value) => {
    const updatedFilters = { ...filterInputs, status: value?.value };
    setStatusOption(value);
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const onResetFilter = () => {
    const resetFilters = {
      ...filterInputs,
      Id:null,
      name: "",
      url: "",
      FromDate: "",
      ToDate: "",
      status: null,
    };
    setFilterInputs(resetFilters);
    setStatusOption(statusOptions[0]);
    updateUrl(resetFilters);
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Xəbər mənbələri filtri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="name"
                  name="name"
                  label="Ad"
                  placeholder="Ad"
                  className="w-100"
                  value={filterInputs.name}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="url"
                  name="url"
                  label="Url"
                  placeholder="Url"
                  className="w-100"
                  value={filterInputs.url}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                  suggestions={statusOptions}
                  handleChangeSingle={handleChangeStatus}
                  single={statusOption}
                  label="Status"
                  placeholder="Status"
                  inputId="roleId"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.FromDate}
                  label="Başlanğıc tarix"
                  onChange={handleChangeStartDate}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.ToDate}
                  label="Son tarix"
                  onChange={handleChangeEndDate}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onResetFilter}>
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
