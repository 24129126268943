import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useUpdateUsersMutation } from "redux/api/Users/usersApi";
import { useGetChooseUserQuery } from "redux/api/Users/usersApi";
import { Formik } from "formik";
import * as Yup from "yup";

export default function Edit(props) {
  const { onHide, show, roles, refetch, id } = props;

  const { data } = useGetChooseUserQuery(id);
  const [pvalues, setPValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = (key) => {
    setPValues({ ...pvalues, [key]: !pvalues[key] });
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [roleOption, setRoleOption] = React.useState(null);
  const [rolesOptions, setRolesOptions] = React.useState([]);

  function handleChangeRole(value, setFieldValue) {
    setRoleOption((prevState) => value);
    setFieldValue("roleId", value.value);
  }

  useEffect(() => {
    setRolesOptions(
      roles.map((suggestion) => ({
        value: suggestion.id,
        label: suggestion.name,
      }))
    );
  }, [roles]);

  useEffect(() => {
    setRoleOption((prev) =>
      rolesOptions.find((option) => option.value === data?.roleId)
    );
  }, [data?.roleId, rolesOptions]);

  useEffect(() => {
    if (!show) {
      setRoleOption(null);
    }
  }, [show]);

  const [
    updateUsers,
    { isLoading, error, isSuccess, isFetching },
  ] = useUpdateUsersMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const onPutUser = (values, resetForm, setSubmitting) => {
    const userUpdate = {
      id: id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };
    updateUsers(userUpdate);
  };

  const EditSchema = Yup.object().shape({
    firstName: Yup.string().required("Ad daxil etməyiniz tələb olunur."),
    lastName: Yup.string().required("Soyad daxil etməyiniz tələb olunur."),
    email: Yup.string()
      .email("Wrong email format. E.g. jhon.doe@example.com")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("İstifadəçinin e-poçt ünvanını daxil etməyiniz tələb olunur."),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      {data && Object.keys(data).length !== 0 && data.constructor === Object && (
        <Formik
          initialValues={{
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutUser(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="edit">Düzəliş et</Modal.Title>
              </Modal.Header>
              <Modal.Body id="userModal">
                <div className="col-12 pl-4 pr-4">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="Ad"
                          placeholder="Ad"
                          className="w-100"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "firstName")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      {touched.firstName && errors.firstName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.firstName}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Soyad"
                          placeholder="Soyad"
                          className="w-100"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "lastName")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      {touched.lastName && errors.lastName ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.lastName}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          className="w-100"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "email")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      {touched.email && errors.email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.email}</div>
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <ReactSelect
                          // isMulti={true}
                          suggestions={rolesOptions}
                          handleChangeSingle={(value) =>
                            handleChangeRole(value, setFieldValue)
                          }
                          single={roleOption}
                          label="Rolu"
                          placeholder="Rolu"
                          inputId="roleId"
                          // onFocus={handleRoleFocus}
                          onKeyDown={handleEnter}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={() => {
                    onHide(false);
                    resetForm();
                  }}
                >
                  Bağla
                </Button>
                <Button type="submit"  className={`px-9`}>
                  Düzəliş et
                  {isLoading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
