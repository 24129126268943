import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import ReactSelect from "../../UI/ReactSelect";
import DeleteIcon from "@material-ui/icons/Delete";
import PlusIcon from "@material-ui/icons/Add";

const ParticipantInformation = ({

  getFieldProps,
  values,
  touched,
  datePathString,
  errors,
  addItem,
  handleRemoveItem,
}) => {
  return (
    <>
      {values?.scrapeDatePathTemplates?.map((_, index) => {
        const scrapeDatePathTemplatesError =
          (errors.scrapeDatePathTemplates?.length &&
            errors.scrapeDatePathTemplates[index]) ||
          {};
        const scrapeDatePathTemplatesTouch =
          (touched.scrapeDatePathTemplates?.length &&
            touched.scrapeDatePathTemplates[index]) ||
          {};

        return (
          <div className="row mt-2 w-100 pl-4 pr-4">
            <div className="col-12 col-md-12 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="datePath1"
                  name="datePath1"
                  label={`${datePathString} ${index + 1}`}
                  placeholder={`${datePathString} ${index + 1}`}
                  className="w-100"
                  {...getFieldProps(
                    `scrapeDatePathTemplates[${index}].datePath`
                  )}
                />
                {scrapeDatePathTemplatesTouch.datePath &&
                scrapeDatePathTemplatesError.datePath ? (
                  <div className="mt-2">
                    <div className="text-danger">
                      {scrapeDatePathTemplatesError["datePath"]
                        ? scrapeDatePathTemplatesError["datePath"]
                        : scrapeDatePathTemplatesError["datePath"]}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-7 col-md-7 col-lg-3">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="dateAttribute"
                  name="dateAttribute"
                  label="Date Attribute"
                  placeholder="Date Attribute"
                  className="w-100"
                  {...getFieldProps(
                    `scrapeDatePathTemplates[${index}].dateAttribute`
                  )}
               
                />
              </div>
            </div>

            <div className="col-3 col-md-3 col-lg-2">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  name="dateFormatOrder"
                  label="Order for date format"
                  placeholder="Order for date format"
                  disabled
                  value={index + 1}
                  inputId="dateFormatOrder"
                  className="w-100"
                />
              </div>
              {scrapeDatePathTemplatesTouch.order &&
              scrapeDatePathTemplatesError.order ? (
                <div className="mt-2">
                  <div className="text-danger">
                    {scrapeDatePathTemplatesError["order"]
                      ? scrapeDatePathTemplatesError["order"]
                      : scrapeDatePathTemplatesError["order"]}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col col-1">
              {index > 0 && (
                <span
                  aria-label="Delete"
                  className="btn mt-5 btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                  onClick={() => {
                    handleRemoveItem(index);
                  }}
                >
                  <DeleteIcon />
                </span>
              )}
            </div>
          </div>
        );
      })}
      {values?.scrapeDatePathTemplates?.length <= 3 && (
        <div className="col col-6 d-flex align-items-end mt-4 p-0">
          <Button onClick={addItem} className={`px-3`}>
            <PlusIcon /> Date Path və sıra əlavə et
          </Button>
        </div>
      )}
    </>
  );
};

export default ParticipantInformation;
