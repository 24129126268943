import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormPart from "./FormPart";
import {
  useGetKeywordQuery,
  useUpdateKeywordMutation,
} from "redux/api/Searchkeyword/searchKeywordApi";

export default function Create(props) {
  const { show, onHide, id } = props;

  const { data } = useGetKeywordQuery(id);
  const initialValues = {
    expression: "",
    description: "",
    searchHelperKeywords: [],
  };

  useEffect(() => {
    if (data) {
      const { id, expression, description, searchHelperKeywords } = data;
      setValues({ id, expression, description, searchHelperKeywords });
    }
  }, [data]);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [updateKeyword, { isLoading, isSuccess }] = useUpdateKeywordMutation();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    searchHelperKeywords: Yup.array()
      .of(
        Yup.object().shape({
          word: Yup.string().required("Söz boş ola bilməz"),
          description: Yup.string().nullable(),
        })
      )
      .test("items-required", "Köməkçi axtariş sözü boş ola bilməz", function(
        value
      ) {
        if (value && value.length > 0) {
          return value.every((item) => item.word);
        }
        return true;
      }),
    expression: Yup.string().required("İfadə daxil etməyiniz tələb olunur."),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }
    return false;
  };

  const onPostHumanResource = (values, resetForm, setSubmitting) => {
    const humanResource = {
      id: id,
      expression: values.expression,
      description: values.description,
      searchHelperKeywords: values.searchHelperKeywords,
    };
    updateKeyword(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { setValues, resetForm, values } = formik;

  const addProtocolParticipants = () => {
    const searchHelperKeywords = [
      ...values.searchHelperKeywords,
      { word: "",description:"" },
    ];
    setValues({ ...values, searchHelperKeywords });
  };

  const handleRemoveParticipantItem = (index) => {
    let searchHelperKeywords = [...values.searchHelperKeywords];
    searchHelperKeywords = searchHelperKeywords.filter((_, i) => i !== index);
    setValues({ ...values, searchHelperKeywords });
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="İfadə"
                    placeholder="İfadə"
                    className="w-100"
                    error={getInputClasses("expression")}
                    {...formik.getFieldProps("expression")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.expression && formik.errors.expression ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.expression}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="address"
                    label="Təsviri"
                    placeholder="Təsviri"
                    className="w-100"
                    error={getInputClasses("description")}
                    {...formik.getFieldProps("description")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.description && formik.errors.description ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <h5 className="mt-5">Köməkçi açar sözlər :</h5>

            <FormPart
              getFieldProps={formik.getFieldProps}
              getInputCheck={getInputClasses}
              addProtocolParticipants={addProtocolParticipants}
              values={formik.values}
              touched={formik.touched}
              errors={formik.errors}
              handleRemoveParticipantItem={handleRemoveParticipantItem}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit" className={`px-9`}>
            Düzəliş et
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
