import {createSlice} from "@reduxjs/toolkit"
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const initialState= {
    errorCount: null,
  
}

export const sourcesSlice = createSlice({
    initialState,
    name: "sourcesSlice",
    reducers: {
        setErrorCount: (state,action) => {
            state.errorCount =action.payload
        },
      
    }
})
export const reducer = persistReducer({
    key: 'MEDIA_MONITOR:ERROR_NOTIFER',
    storage,
    whitelist: ['errorCount']
},
sourcesSlice.reducer);

export default reducer;

export const {setErrorCount} = sourcesSlice.actions