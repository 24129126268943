import React, { useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDeleteEmployeesMutation } from "redux/api/Employees/employeesApi";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100% !important",
    height: "100px !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

export default function Remove(props) {
  const classes = useStyles();
  const { onHide, show, id, refetch } = props;

  const form = useRef(null);

  const [
    deleteEmployees,
    { isLoading, error, isSuccess },
  ] = useDeleteEmployeesMutation();

  const onDeleteUser = (e) => {
    e.preventDefault();
    deleteEmployees({ id });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteUser} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">Silməyinizə əminsiniz?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide(false)}>
            Ləğv
          </Button>
          <Button type="submit">Sil</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
