import {createSlice} from "@reduxjs/toolkit"




const initialState= {
    keyword: [],
  
}

export const keywordSlice = createSlice({
    initialState,
    name: "keywordSlice",
    reducers: {
        setKeyword: (state,action) => {
            state.keyword =action.payload
        },
      
    }
})

export default keywordSlice.reducer;
export const {setKeyword} = keywordSlice.actions