import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const employeesApi = createApi({
  reducerPath: "employeesApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ["Employee", "Clients"],

  endpoints: (builder) => ({
    getEmployees: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter; // ResponsiblePerson , RegionalCenterName ,CityName

        return {
          url: `clients?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.Name ? `&Name=${name.Name}` : ""
          }${name?.Username ? `&Username=${name.Username}` : ""}${
            name?.deleted === true
              ? `&Deleted=${name?.deleted}`
              : name?.deleted === false
              ? `&Deleted=${name?.deleted}`
              : ""
          }`,
        };
      },
      providesTags: ["Employee"],
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getEmployee: builder.query({
      query(id) {
        return {
          url: `clients/${id}`,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getClientWeekDay: builder.query({
      query(id) {
        return {
          url: `client-mail-days-weeks?ClientId=${id}`,
        };
      },
      providesTags: ["Clients"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getClientWeekDayItem: builder.query({
      query(id) {
        return {
          url: `client-mail-days-weeks/${id}`,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getEmployeeNewsSources: builder.query({
      query(id) {
        return {
          url: `clients/${id}/news-sources`,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createEmployees: builder.mutation({
      query(form) {
        return {
          url: "clients",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Employee"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createClientWeekDay: builder.mutation({
      query(form) {
        return {
          url: "client-mail-days-weeks",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Clients"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createEmployeeNewsSources: builder.mutation({
      query(form) {
        return {
          url: `clients/${form?.id}/news-sources`,
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Employee"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateClientsPassword: builder.mutation({
      query(data) {
        return {
          url: "clients/reset-password",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateEmployees: builder.mutation({
      query(form) {
        return {
          url: "clients",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateClientWeekDay: builder.mutation({
      query(form) {
        return {
          url: "client-mail-days-weeks",
          method: "PUT",
          data: form,
        };
      },
      invalidatesTags: ["Clients"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateEmployeStatus: builder.mutation({
      query(data) {
        return {
          url: "clients/status",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Employee"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteEmployees: builder.mutation({
      query(form) {
        return {
          url: `clients/${form?.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Employee"],
    }),
    deleteClientWeekDay: builder.mutation({
      query(id) {
        return {
          url: `client-mail-days-weeks/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetClientWeekDayQuery,
  useGetClientWeekDayItemQuery,
  useUpdateClientsPasswordMutation,
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useCreateEmployeesMutation,
  useDeleteEmployeesMutation,
  useUpdateEmployeesMutation,
  useUpdateEmployeStatusMutation,
  useGetEmployeeNewsSourcesQuery,
  useCreateEmployeeNewsSourcesMutation,
  useCreateClientWeekDayMutation,
  useUpdateClientWeekDayMutation,
  useDeleteClientWeekDayMutation,
} = employeesApi;
