import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DatePicker from "app/modules/UI/Datetimepicker";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import ReactSelect from "app/modules/UI/ReactSelect";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage,
    page,
    rowsPerPage
  } = props;
  const history = useHistory();
  const { data } = useGetSourcesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  const [sourcesOptions, setsourcesOptions] = React.useState(null);
  const [sourcesOption, setsourcesOption] = React.useState(null);

  const updateUrl = (newFilterInputs) => {
    const params = new URLSearchParams();
    Object.keys(newFilterInputs).forEach((key) => {
      const value = newFilterInputs[key];
      if (value !== null && value !== "") {
        params.append(key, value);
      }
    });
    history.push(`?${params.toString()}`);
  };

  const handleFilterChange = (e) => {
    const updatedFilters = { ...filterInputs, [e.target.name]: e.target.value };
    setFilterInputs(updatedFilters);
    filterInputsUpdate(e);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeStartDate = (e) => {
    const updatedFilters = { ...filterInputs, FromDate: e.target.value };
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangeEndDate = (e) => {
    const updatedFilters = { ...filterInputs, ToDate: e.target.value };
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const handleChangesources = (value) => {
    const updatedFilters = { ...filterInputs, NewsSourceId: value?.value };
    setsourcesOption(value);
    setFilterInputs(updatedFilters);
    setPage(0);
    updateUrl(updatedFilters);
  };

  const onResetFilter = () => {
    const resetFilters = {
      page,
      rowsPerPage,
      Description: "",
      NewsSourceId: "",
      FromDate: "",
      ToDate: "",
    };
    
    setsourcesOption(null);
    setFilterInputs(resetFilters);
    updateUrl(resetFilters);
  };

  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setsourcesOptions([{ label: "Heçbiri", value: null }, ...newSelectData]);
    }
  }, [data]);

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Kataqoriya filtri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="name"
                  name="Description"
                  label="Bildiriş"
                  placeholder="Bildiriş"
                  className="w-100"
                  value={filterInputs.Description}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                  suggestions={sourcesOptions}
                  handleChangeSingle={handleChangesources}
                  single={sourcesOption}
                  label="Xəbər mənbəsi"
                  placeholder="Xəbər mənbəsi"
                  inputId="sourceId"
                  isClearable
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.FromDate}
                  label="Başlanğıc tarix"
                  onChange={handleChangeStartDate}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.ToDate}
                  label="Son tarix"
                  onChange={handleChangeEndDate}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onResetFilter}>
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
