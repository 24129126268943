import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const templatesApi = createApi({
  reducerPath: "templatesApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ["Template"],
  endpoints: (builder) => ({
    getScrapeTemplates: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;

        return {
          url: `news-source-scrape-templates?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.NewsSourceId ? "&NewsSourceId=" + name?.NewsSourceId : ""
          }${name?.Id ? "&Id=" + name?.Id : ""}${
            name?.name ? "&Name=" + name?.name : ""
          }${name?.FromDate ? "&FromDate=" + name?.FromDate : ""}${
            name?.ToDate ? "&ToDate=" + name?.ToDate : ""
          }`,
        };
      },
      providesTags: ["Template"],
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getScrapeTemplate: builder.query({
      query(id) {
        return {
          url: `news-source-scrape-templates/${id}`,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createTemplates: builder.mutation({
      query(form) {
        return {
          url: "news-source-scrape-templates",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Template"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateTemplate: builder.mutation({
      query(data) {
        return {
          url: "news-source-scrape-templates",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Template"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    changeStatus: builder.mutation({
      query(data) {
        return {
          url: `news-source-scrape-templates/${data?.id}/status`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Template"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteScrapeTemplate: builder.mutation({
      query(id) {
        return {
          url: `news-source-scrape-templates/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["Template"],
    }),
  }),
});

export const {
  useGetScrapeTemplatesQuery,
  useGetScrapeTemplateQuery,
  useChangeStatusMutation,
  useUpdateTemplateMutation,
  useCreateTemplatesMutation,
  useDeleteScrapeTemplateMutation,
} = templatesApi;
