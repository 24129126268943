import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField/index";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Scope from "../components/ScopeCreate";
import { useCreateRolesMutation } from "../../../../redux/api/Roles/rolesApi";
import {
  useGetUsersPermissonsQuery
} from "../../../../redux/api/Users/usersApi";
import { resetPermission } from "../../../../redux/features/Role/RoleSlicer";



const initialValues = {
  name: "",
  permissions: [],
};
export default function Create(props) {
  const { show, onHide, refetch } = props;

  const dispatch = useDispatch();

  const [isError, setIsError] = useState("");;
  const [permissionsOption, setPermissionsOption] = React.useState(null);
  const [chooseScope, setChooseScope] = useState({});
  const [oldValue, setOldValue] = useState([]);
  const {permissions} = useSelector((state)=>state.usersState)

  const [createRoles, {  error, isSuccess ,isLoading}] =
    useCreateRolesMutation();
    const {Pdata} = useGetUsersPermissonsQuery();

    useEffect(() => {
      if (isSuccess) {
        refetch()
        onHide()
      }
     
      }, [isLoading]);

  const CreateSchema = Yup.object().shape({
     name: Yup.string().required("Ad daxil etməyiniz tələb olunur"),
    // permissions: Yup.string().required("Icazə tələb olunur"),
  });

  const {permissions2} = useSelector((state)=>state.rolesSlicer)


  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const onPostRole = (values, resetForm, setSubmitting) => {
    let permissons = [];
    for (let key in chooseScope) {
      permissons = [...permissons, chooseScope[key].scope];
    }
    const role = {
      name: values.name,
      permissons: values.permissons,
    };

  };    

  const onPostHumanResource = (values, resetForm, setSubmitting) => {
    const humanResource = {
      name: values.name,
      permissons: permissions2,

    };

    createRoles(humanResource)
    dispatch(resetPermission())



  };
  const deleteScope = (key) => {
    let cloneScope = { ...chooseScope };
    delete cloneScope[key];
    setChooseScope(cloneScope);
  };

  function handleChangePermissons(value) {
    setPermissionsOption(value);

    const newPermissons = value
      ? value.map((v) => {
        const name = `Checkboxes[${v.value}]`;
        if (chooseScope[name] === undefined) {
          changeRadioButton(name, false, false, v.value);
        }

        return v.value;
      })
      : [];

    setOldValue(newPermissons);

    formik.setFieldValue("permissions", newPermissons);

    let difference = oldValue.filter((x) => !newPermissons.includes(x));

    difference.length && deleteScope(`Checkboxes[${difference[0]}]`);
  }

  const changeRadioButton = (
    name,
    valid,
    touch,
    permissonId,
    value = null,
    scopeId = null
  ) => {
    const newScope = {
      ...chooseScope,
      [name]: {
        valid: valid,
        touch: touch,
        message: "İcazənin növünü seçin",
        scope: {
          id: permissonId,
          scopeId: scopeId,
          value: value,
        },
      },
    };

    setChooseScope(newScope);
  };




  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
  
      onPostHumanResource(values, resetForm, setSubmitting);
  
    },
  });

  const resetForm = () => {
    onHide();
    dispatch(resetPermission())
    formik.resetForm();
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={resetForm}
      /*
     size="md" */
      aria-labelledby="edit"
      centered
      /* className="modal-right pr-0" */
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="edit">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>
              

               <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {permissions &&
                    permissions.map(({ id, name, parameters }) => {
                      const permissonId = id;
                      const name2 = `Checkboxes[${permissonId}]`;
                      return (
                        <Scope
                          key={id}
                          name={name2}
                          permissonId={permissonId}
                          changeRadioButton={changeRadioButton}
                          parameters={parameters}
                          label={name}
                          chooseScope={chooseScope}
                        />
                      );
                    })}
                </div>
                  </div>
        
                  
            </div>
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              dispatch(resetPermission())
              formik.resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit" className={isLoading ? "px-9" : ""}>
            <span>Əlavə et</span>
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
