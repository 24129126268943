import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import RenderIf from "../UI/RenderIf";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  useGetEmployeeNewsSourcesQuery,
  useCreateEmployeeNewsSourcesMutation,
} from "redux/api/Employees/employeesApi";

export default function ClientSources() {
  const [options, setOptions] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [sequenceNumbers, setSequenceNumbers] = useState({});

  const { id } = useParams();
  let location = useLocation();
  let history = useHistory();
  
  const EmployeeData = useGetEmployeeNewsSourcesQuery(id);
  const [createEmployeeNewsSources, { isLoading: createLoading }] = useCreateEmployeeNewsSourcesMutation();
  
  const { data: sources, isLoading } = useGetSourcesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
    status: true,
  });

  const onPostClientNewsSource = () => {
    const payload = selectedSources.map(source => ({
      sourceId: source.id,
      sequenceNumber: sequenceNumbers[source.id] || 1, // Default sequence number if not entered
    }));

    createEmployeeNewsSources({ id, sources: payload });
  };

  const handleCheckboxChange = (source) => {
    setSelectedSources(prev =>
      prev.includes(source)
        ? prev.filter(item => item.id !== source.id)
        : [...prev, source]
    );
  };

  const handleSequenceChange = (id, value) => {
    setSequenceNumbers(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <div className="row bg-white rounded p-4">
      <div className="col-12 d-flex align-items-center py-4 height-65 justify-content-between">
        <button onClick={() => history.goBack()} className="handleGoBack">
          <KeyboardBackspaceIcon /> Geri
        </button>
        <h1 className="display-4 mb-0 m-4">{location?.state} xəbər mənbələri</h1>
        <Button
          type="submit"
          className={`px-9`}
          onClick={() => onPostClientNewsSource()}
        >
          {createLoading && <span className="mr-8 spinner spinner-white"></span>}
          Seçilmiş xəbər mənbələrini əlavə et
        </Button>
      </div>

      <RenderIf condition={isLoading}>
        <div className="d-flex justify-content-center align-items-center w-100">
          <CircularProgress />
        </div>
      </RenderIf>

      <RenderIf condition={sources?.data && !isLoading}>
        <div className="row">
          {sources?.data.map((source, index) => (
            <div key={source.id} className="col-12 d-flex align-items-center mb-3">
              <input
                type="checkbox"
                checked={selectedSources.some(item => item.id === source.id)}
                onChange={() => handleCheckboxChange(source)}
              />
              <label className="ml-2">{source.name}</label>

              <input
                type="number"
                className="form-control ml-4"
                placeholder="Sıra nömrəsi"
                value={sequenceNumbers[source.id] || ""}
                onChange={(e) => handleSequenceChange(source.id, e.target.value)}
                style={{ width: "100px" }}
              />
            </div>
          ))}
        </div>
      </RenderIf>

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
