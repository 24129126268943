import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import MailPart from "../components/MailPart";
import CategoryPart from "../components/CategoryPart";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import * as Yup from "yup";
import {
  useUpdateEmployeesMutation,
  useGetEmployeeQuery,
} from "redux/api/Employees/employeesApi";
import { useGetCategoriesQuery } from "redux/api/Categories/categoriesApi";

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const [categoryOptions, setCategoryOptions] = React.useState(null);
  const [categoryOptionsFilter, setCategoryOptionsFilter] = React.useState(null);
  const [categoryValue, setCategoryValue] = useState();

  const initialValues = {
    name: "",
    username: "",
    password: "",
    clientNewsSources: [],
    clientCategories: [
      {
        categoryId: "",
        order: "",
      },
    ],
    clientMails: [
      {
        email: "",
      },
    ],
  };

  const { data } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoryOptions([...newSelectData]);
    }
  }, [data]);

  const EmployeeData = useGetEmployeeQuery(id);
  const EmployeeDatas = EmployeeData?.data;

  useEffect(() => {
    if (categoryOptions && EmployeeDatas?.clientCategories) {
      setCategoryValue(
        EmployeeDatas?.clientCategories?.map((el) =>
          categoryOptions?.find((mo) => mo?.value == el?.categoryId)
        )
      );
    }

  }, [EmployeeDatas, categoryOptions]);

  useEffect(() => {
    if (categoryOptions && categoryValue) {
      const filteredCategoryOptions = categoryOptions?.filter(
        (option) =>
          !categoryValue?.some((selected) => selected?.value === option.value)
      );
      setCategoryOptionsFilter(filteredCategoryOptions);
    }
  }, [categoryValue]);

  useEffect(() => {
    if (EmployeeData?.data) {
      const {
        name,
        username,
        clientCategories,
        clientMails,
        clientMailHours,
      } = EmployeeData?.data;
      setValues({
        id,
        name,
        username,
        clientCategories,
        clientMails,
        clientMailHours,
      });
    }
  }, [EmployeeData?.data]);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateEmployees,
    { isLoading, isSuccess, isError: EditError },
  ] = useUpdateEmployeesMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    name: Yup.string().required("Ad daxil etməyiniz tələb olunur."),
    username: Yup.string().required(
      "İstifadəçi adı daxil etməyiniz tələb olunur"
    ),
    clientMails: Yup.array()
      .required("Email boş ola bilməz")
      .of(
        Yup.object().shape({
          email: Yup.string().required("Email daxil etməyiniz tələb olunur"),
        })
      ),

    clientCategories: Yup.array()
      .required("Kateqoriya və order boş ola bilməz")
      .of(
        Yup.object().shape({
          categoryId: Yup.string().required(
            "Kateqoriya daxil etməyiniz tələb olunur"
          ),
          order: Yup.string().required("Sıra daxil edin"),
        })
      ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      updateEmployees({ ...values, id });
    },
  });
  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const { resetForm, values, setValues } = formik;
  const addProtocolParticipants = () => {
    const clientMails = [...values.clientMails, { email: "" }];

    setValues({ ...values, clientMails });
  };

  const handleRemoveParticipantItem = (index) => {
    let clientMails = [...values.clientMails];
    clientMails = clientMails.filter((_, i) => i !== index);
    setValues({ ...values, clientMails });
  };

  function handleChangeCategory(value, index) {
    const newCategoryValues = [...categoryValue];
    newCategoryValues[index] = value;
    setCategoryValue(newCategoryValues);
    formik.setFieldValue(`clientCategories[${index}].categoryId`, value.value);
  }
  const showSecond = false;

  const addCategory = () => {
    const clientCategories = [
      ...values.clientCategories,
      { categoryId: "", order: "" },
    ];

    setValues({ ...values, clientCategories });
  };

  const handleRemoveCategory = (index) => {
    let clientCategories = [...values.clientCategories];
    clientCategories = clientCategories.filter((_, i) => i !== index);
    setValues({ ...values, clientCategories });

    setCategoryValue(categoryValue.filter((_, i) => i !== index));
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <form onSubmit={formik.handleSubmit} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="edit">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="username"
                    label="İstifadəçi adı"
                    placeholder="İstifadəçi adı"
                    className="w-100"
                    error={getInputClasses("username")}
                    {...formik.getFieldProps("username")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-5 row">
              <div className="col-6">
                <h3>Kategoriya və Sıra :</h3>
              </div>
              <div className="col-6">
                <h3> Email ünvanları:</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <CategoryPart
                  getFieldProps={formik.getFieldProps}
                  getInputCheck={getInputClasses}
                  addProtocolParticipants={addCategory}
                  categoryOptions={categoryOptionsFilter}
                  handleChangeCategory={handleChangeCategory}
                  values={formik.values}
                  singleValue={categoryValue}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleRemoveParticipantItem={handleRemoveCategory}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <MailPart
                  getFieldProps={formik.getFieldProps}
                  getInputCheck={getInputClasses}
                  addProtocolParticipants={addProtocolParticipants}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleRemoveParticipantItem={handleRemoveParticipantItem}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit" className={`px-9`}>
            Düzəliş et
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
