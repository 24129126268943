import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDeleteVideoNewsMutation } from "redux/api/VideoNews/VideoNewsApi";



export default function Remove(props) {

  const { onHide, show, id } = props;

  const [
    deleteVideoNews,
    { isLoading,  isSuccess },
  ] = useDeleteVideoNewsMutation();

  useEffect(() => {
    if (isSuccess) {
      onHide();
    }
  }, [isLoading]);

  const onDeleteUser = (e) => {
    e.preventDefault();
    deleteVideoNews(id);
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteUser}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4 mb-3">
            <div className="row">Silmək istədiyinizdən əminsinizmi?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" variant="danger">
            Sil
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
