import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import {
  useCreateTvChannelsMutation,
} from "redux/api/TvChannels/TvChannelsApi";

export default function Create(props) {
  const { show, onHide } = props;
 



  const [
    createTvChannels,
    { isLoading,  isSuccess },
  ] = useCreateTvChannelsMutation();

  const initialValues = {
    name: "",
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Məlumat daxil etməyiniz tələb olunur."),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostHumanResource = (values) => {
    const humanResource = {
      name: values.name,
    };

    createTvChannels(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm,  } = formik;
  



  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="name"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>
           
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
          
            }}
          >
            Bağla
          </Button>
          <Button type="submit"  className={`px-9`}>
            Əlavə et
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
