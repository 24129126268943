import React from "react";
import { Modal, Button } from "react-bootstrap";
import TableCheck from "../components/checkTable";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetSourcesItemPathCheckQuery } from "redux/api/NewsSourcesPath/newsSourcesPathApi";
import RenderIf from "app/modules/UI/RenderIf";

export default function Create(props) {
  const { show, onHide,  id } = props;


  const { data, isLoading} = useGetSourcesItemPathCheckQuery(id);
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
 

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form>
        <Modal.Header closeButton className="text-center">
          <Modal.Title id="create"> </Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          {isLoading ? (
            <>
              <div className="d-flex align-items-center justify-content-center flex-column h-100 check_info_spinner">
                <CircularProgress />

                <h1 className="load_data_info">Məlumatlar yüklənir...</h1>
              </div>
            </>
          ) : (
            <>
              <RenderIf
                renderElse={
                  <>
                    {" "}
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <h3>
                        <strong> {data?.news?.title}</strong>
                      </h3>
                      <h3>{formatDate(data?.news?.newsDate)}</h3>
                      <p>{data?.news?.article}</p>
                    </div>
                    <h3>
                        <strong> Tapılan xəbər sayı :{data?.newsLists?.length} </strong>
                      </h3>
                    <TableCheck rows={data?.newsLists} />
                  </>
                }
                condition={!data?.newsLists.length && !isLoading}
              >
                <AlertComponent
                  variant="info"
                  message="Heç bir məlumat tapılmadı"
                />
              </RenderIf>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Bağla
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
