import {createSlice} from "@reduxjs/toolkit"




const initialState= {
    proxies: [],
  
}

export const proxiesSlice = createSlice({
    initialState,
    name: "proxiesSlice",
    reducers: {
        setProxies: (state,action) => {
            state.sources =action.payload
        },
      
    }
})

export default proxiesSlice.reducer;
export const {setProxies} = proxiesSlice.actions