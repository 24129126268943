import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import HourPart from "../components/HourPart";
import ReactSelect from "../../UI/ReactSelect";
import { useCreateClientWeekDayMutation } from "redux/api/Employees/employeesApi";
import RenderIf from "app/modules/UI/RenderIf";





export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [iserror, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [defaultState, setDefaultState] = useState(false);
  const [sourceOption, setSourceOption] = React.useState(null);
  const { id } = useParams();

  const initialValues = {
    clientId: Number(id),
    daysOfWeek: "",
    daysWeekMailHours: [
      {
        hour: "",
      },
    ],
  };

  const options = [
    { value: 1, label: "Bazar Ertəsi" },
    { value: 2, label: "Çərşənbə axşamı" },
    { value: 3, label: "Çərşənbə" },
    { value: 4, label: "Cümə axşamı" },
    { value: 5, label: "Cümə" },
    { value: 6, label: "Şənbə" },
    { value: 7, label: "Bazar" },
  ];

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    createClientWeekDay,
    { isLoading, error, isSuccess ,isError},
  ] = useCreateClientWeekDayMutation();
  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    // name: Yup.string().required("Başlıq daxil etməyiniz tələb olunur."),
    // parentCategoryId: Yup.number()
    //   .nullable(true)
    //   .required("Üst kateqoriya daxil etməyiniz tələb olunur."),
    // searchKeywords: Yup.string().required(
    //   "Açar sözlər  daxil etməyiniz tələb olunur."
    // ),
  });

  function handleChangeRole(value) {
    setSourceOption(value);
    setFieldValue("daysOfWeek", value?.value);
  }

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostHumanResource = (values) => {
    createClientWeekDay(values );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm, values, setFieldValue, setValues } = formik;

  useEffect(() => {
    setIsError("");
  }, [show]);

 

  const addHour = () => {
    const daysWeekMailHours = [...values.daysWeekMailHours, { hour: "" }];

    setValues({ ...values, daysWeekMailHours });
  };

  const handleRemoveHour = (index) => {
    let daysWeekMailHours = [...values.daysWeekMailHours];
    daysWeekMailHours = daysWeekMailHours.filter((_, i) => i !== index);
    setValues({ ...values, daysWeekMailHours });
  };
  const showSecond = false;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  function handleChangeHour(value, index) {
    formik.setFieldValue(
      `daysWeekMailHours[${index}].hour`,
      value && value.format(str)
    );
  }
  useEffect(() => {
    if (isSuccess) {
      onHide();
    }
   
  }, [isLoading]);
  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={options}
                    handleChangeSingle={(value) => handleChangeRole(value)}
                    label="Həftənin günü"
                    placeholder="Həftənin günü"
                    inputId="daysOfWeek"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.daysOfWeek && formik.errors.daysOfWeek
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.daysOfWeek}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <HourPart
                  getFieldProps={formik.getFieldProps}
                  getInputCheck={getInputClasses}
                  addHour={addHour}
                  handleChangeHour={handleChangeHour}
                  handleEnter={handleEnter}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleRemoveHour={handleRemoveHour}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Əlavə et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
