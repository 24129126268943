import React, { useRef, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import { FiExternalLink } from "react-icons/fi";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import PlusIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import CustomPagination from "app/modules/UI/CustomPagination";
import { GrPowerReset } from "react-icons/gr";

const headRows = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "Bildiriş",
  },
  {
    id: "url",
    numeric: false,
    disablePadding: true,
    label: "Url",
  },
  {
    id: "lastLoginDate",
    numeric: false,
    disablePadding: true,
    label: "Xəbər mənbəsi",
  },
  {
    id: "port",
    numeric: false,
    disablePadding: true,
    label: "Scrape Şablon",
  },
  {
    id: "helperwords",
    numeric: false,
    disablePadding: true,
    label: "Source Path",
  },
  {
    id: "helperwords",
    numeric: false,
    disablePadding: true,
    label: "Tarix",
  },
  { id: "tools", numeric: false, disablePadding: true, label: "Əməliyyatlar" },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    deleteUsersIds,
    onRequestSort,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="checkbox_cell">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "Select all desserts" }}
          />
        </TableCell>

        {headRows.map((row) => (
          <TableCell
            className="fixed_cell"
            key={row.id}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? false : false}
          >
            {row.id === "tools" ? (
              row.label
            ) : (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order ? "asc" : "desc"}
                onClick={() => {
                  return row.id !== "tools" ? onRequestSort(row.id) : null;
                }}
              >
                {row.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    flexWrap: "wrap",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  label: {
    fontSize: ".875rem",
    width: "100%",
  },
  toolbar: {
    zIndex: "10",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    setOpenCreate,
    setOpenRemoveAll,
    handleRefetch,
    tableHeaderRef,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
      ref={tableHeaderRef}
    >
      <div className="d-flex w-100">
        <div className={classes.title}>
          {numSelected > 0 && (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div className="d-flex align-items-center">
            <button className="reset_wrapper mr-2" onClick={handleRefetch}>
              <GrPowerReset />
            </button>
            <Tooltip title="Sil">
              <IconButton
                className="mr-2 delete-selected"
                aria-label="Delete"
                onClick={() => {
                  setOpenRemoveAll(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Tooltip title="Əlavə et" placement="top">
          <button
            className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary mr-2 h5 rounded-circle flex-shrink-0"
            onClick={() => setOpenCreate(true)}
          >
            <PlusIcon />
          </button>
        </Tooltip>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  height: {
    height: "calc(100% - 65px)",
  },
  root: {
    width: "100%",
    height: "100%",
    padding: "0px",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  toolbar: {
    minHeight: theme.spacing(2),
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export default function EnhancedTable(props) {
  const {
    setSortField,
    setOrderByTable,
    sortField,
    orderBy,
    setOpenRemove,
    setOpenRemoveAll,
    setOpenCreate,
    isLoaded,
    permissions,
    error,
    rows,
    page,
    rowsPerPage,
    count,
    setProxiesId,
    setDeleteUsersID,
    deleteUsersIds,
    filterInputsUpdate,
    filterInputs,
    setPage,
    setFilterInputs,
    setRowsPerPage,
    filterStatusesUpdate,
    handleChangeRowsPerPage,
    handleChangePage,
    roles,
    handleRefetch,
  } = props;
  const classes = useStyles();

  function handleRequestSort(property) {
    const isDesc = sortField === property && !orderBy;

    setOrderByTable(isDesc);
    setSortField(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);

      setDeleteUsersID(newSelecteds);
      return;
    }

    setDeleteUsersID([]);
  }

  function handleClick(event, id) {
    const selectedIndex = deleteUsersIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(deleteUsersIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(deleteUsersIds.slice(1));
    } else if (selectedIndex === deleteUsersIds.length - 1) {
      newSelected = newSelected.concat(deleteUsersIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        deleteUsersIds.slice(0, selectedIndex),
        deleteUsersIds.slice(selectedIndex + 1)
      );
    }

    setDeleteUsersID(newSelected);
  }

  const isSelected = (id) => deleteUsersIds.indexOf(id) !== -1;

  const { pathname } = useLocation();
  const history = useHistory();
  const content = useRef(null);
  const tableHeader = useRef(null);
  const tableWrapperHeader = useRef(null);

  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);

  useEffect(() => {
    let paperHeigth = content?.current?.offsetHeight;
    let tableHeaderHeight = tableHeader?.current?.offsetHeight;
    setTableWrapperHeight(paperHeigth - tableHeaderHeight - 52);
  }, [pathname]);

  const handleRoutePath = (sourceId, id, name) => {
    history.push(`/news-sources/${sourceId}/path?${id}`, name);
  };
  const handleRouteSource = (sourceId, id, name) => {
    history.push(`/news-sources/${sourceId}/scrape-templates?${id}`, name);
  };

  const handleRouteNewsSource = (sourceId) => {
    history.push(`/news-sources?Id=${sourceId}`);
  };

  return (
    <div className={`col-12 ${classes.height}`}>
      <div className={classes.root}>
        <Paper className={classes.paper} ref={content}>
          {isLoaded && !rows.length && (
            <div className={classes.progresRoot}>
              <CircularProgress className={classes.progress} />
            </div>
          )}

          <EnhancedTableToolbar
            numSelected={deleteUsersIds?.length}
            setOpenCreate={setOpenCreate}
            setOpenRemoveAll={setOpenRemoveAll}
            User_Add={permissions?.User_Add}
            filterInputsUpdate={filterInputsUpdate}
            filterInputs={filterInputs}
            setFilterInputs={setFilterInputs}
            filterStatusesUpdate={filterStatusesUpdate}
            roles={roles}
            tableHeaderRef={tableHeader}
            handleRefetch={handleRefetch}
          />
          <div
            className={classes.tableWrapper}
            ref={tableWrapperHeader}
            style={{ maxHeight: tableWrapperHeight + "px" }}
          >
            {rows.length ? (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <EnhancedTableHead
                  numSelected={deleteUsersIds?.length}
                  order={orderBy}
                  orderBy={sortField}
                  deleteUsersIds={deleteUsersIds}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          className="fixed-row-cell"
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.description}
                        </TableCell>
                        <TableCell>
                          <a target="_blank" href={row?.url}>
                            {row?.url}
                          </a>
                        </TableCell>
                        <TableCell className=" extarnal_link_wrap">
                          <p>
                            <a target="_blank" href={row.newsSource?.url}>
                              {row.newsSource?.name}
                            </a>
                          </p>
                          {row.newsSource?.id && (
                            <span
                              className="extarnal_link text-right"
                              onClick={() =>
                                handleRouteNewsSource(row.newsSource?.id)
                              }
                            >
                              <FiExternalLink />
                            </span>
                          )}
                        </TableCell>
                        <TableCell className=" extarnal_link_wrap">
                          <p>{row.newsSourceScrapeTemplate?.name}</p>
                          {row.newsSourceScrapeTemplate?.id && (
                            <span
                              className="extarnal_link text-right"
                              onClick={() =>
                                handleRouteSource(
                                  row.newsSource?.id,
                                  row.newsSourceScrapeTemplate?.id,
                                  row.newsSource?.name
                                )
                              }
                            >
                              <FiExternalLink />
                            </span>
                          )}
                        </TableCell>
                        <TableCell className=" extarnal_link_wrap">
                          <p>
                            {" "}
                            <a target="_blank" href={row?.newsSourcePath?.url}>
                              {row?.newsSourcePath?.url}
                            </a>
                          </p>

                          {row.newsSourcePath?.id && (
                            <span
                              className="extarnal_link"
                              onClick={() =>
                                handleRoutePath(
                                  row.newsSource?.id,
                                  row?.newsSourcePath?.id,
                                  row.newsSource?.name
                                )
                              }
                            >
                              <FiExternalLink />
                            </span>
                          )}
                        </TableCell>
                        <TableCell padding="none" className="pr-4 ">
                          {row?.created
                            ? format(
                                new Date(row?.created),
                                "dd-MM-yyyy  HH:mm"
                              )
                            : ""}
                        </TableCell>
                        <TableCell padding="none" className="pr-4" align="left">
                          <Tooltip title="Sil" placement="top">
                            <button
                              aria-label="Delete"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                              onClick={() => {
                                setOpenRemove(true);
                                setProxiesId(row.id);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : !rows.length && !isLoaded && !error ? (
              <AlertComponent
                variant="info"
                message="Heç bir məlumat tapılmadı"
              />
            ) : (
              !isLoaded &&
              error && (
                <AlertComponent
                  variant="danger"
                  message="We've lost the connection"
                />
              )
            )}
          </div>
          {count > 10 && (
            <CustomPagination
              count={count}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
    </div>
  );
}
