import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "app/modules/UI/Datetimepicker";
import { useGetTvChannelsQuery } from "redux/api/TvChannels/TvChannelsApi";
import ReactSelect from "app/modules/UI/ReactSelect";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage

  } = props;
  const { data } = useGetTvChannelsQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });
  const [tvchannelsOptions, settvchannelsOptions] = React.useState(null);
  const [tvchannelsOption, settvchannelsOption] = React.useState(null);

  const onResetFilter = () => {
    settvchannelsOption(null);
    setFilterInputs({
      VideoUrl: "",
      Title: "",
      TvChannelId: "",
      FromDate: "",
      ToDate: "",
    });
  };

  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      settvchannelsOptions(newSelectData);
    }
  }, [data]);
  function handleChangeStartDate(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      FromDate: e.target.value,
    });
  }
  function handleChangeEndDate(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      ToDate: e.target.value,
    });
  }
  function handleChangeCategories(value) {
    setPage(0);
    settvchannelsOption(value);
    setFilterInputs({
      TvChannelId: value?.value,
    });
  }

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Kataqoriya filtri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="VideoUrl"
                  name="VideoUrl"
                  label="Video Url"
                  placeholder="Video Url"
                  className="w-100"
                  value={filterInputs.VideoUrl}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="Title"
                  name="Title"
                  label="Başlıq"
                  placeholder="Başlıq"
                  className="w-100"
                  value={filterInputs.Title}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                  suggestions={tvchannelsOptions}
                  handleChangeSingle={(value) => handleChangeCategories(value)}
                  single={tvchannelsOption}
                  label="Tv Kanal"
                  placeholder="Tv Kanal"
                  inputId="roleId"
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.FromDate}
                  label="Başlanğıc tarix"
                  onChange={(e) => handleChangeStartDate(e)}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.ToDate}
                  label="Son tarix"
                  onChange={(e) => handleChangeEndDate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onResetFilter();
          }}
        >
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
