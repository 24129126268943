import React from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import "_metronic/_assets/plugins/flaticon2/flaticon.css";
import { useGetSourcesItemPathQuery } from "redux/api/NewsSourcesPath/newsSourcesPathApi";
const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
});

export default function View(props) {
  const { onClose, id } = props;
  const { data } = useGetSourcesItemPathQuery(id);
  const classes = useStyles();

  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">Xəbər Mənbələri Paths</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div className="drawer-body">
        <Card className={classes.card}>
          <CardHeader
            title="Ümumi məlumatlar"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />
          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Address-card.svg"
                )}
                className="icon-primary"
              />
            }
            title={data?.dateCulture}
            subheader="Saytın dili"
            className="p-3"
          />
          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Adress-book2.svg"
                )}
                className="icon-primary"
              />
            }
            title={data?.url}
            subheader="Url"
            className="p-3"
          />
        </Card>
    
      </div>
    </div>
  );

  return (
    <Drawer {...props} anchor="right" className="drawer--fixed">
      {sideList("right")}
    </Drawer>
  );
}
