import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const tvChannelsApi = createApi({
  reducerPath: "TvChannelsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ["TvChannels"],
  endpoints: (builder) => ({
    getTvChannels: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;

        return {
          url: `tv-channels?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.name ? "&name=" + name?.name : ""
          }`,
        };
      },
      providesTags: ["TvChannels"],
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getTvChannelsItem: builder.query({
      query(id) {
        return {
          url: `tv-channels/${id}`,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    createTvChannels: builder.mutation({
      query(form) {
        return {
          url: "tv-channels",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["TvChannels"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateTvChannels: builder.mutation({
      query(data) {
        return {
          url: "tv-channels",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["TvChannels"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    changeStatus: builder.mutation({
      query(data) {
        return {
          url: `tv-channels/${data?.id}/status`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["TvChannels"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteTvChannels: builder.mutation({
      query(id) {
        return {
          url: `tv-channels/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["TvChannels"],
    }),
  }),
});

export const {
  useChangeStatusMutation,
  useGetTvChannelsQuery,
  useGetTvChannelsItemQuery,
  useUpdateTvChannelsMutation,
  useCreateTvChannelsMutation,
  useDeleteTvChannelsMutation,
} = tvChannelsApi;
