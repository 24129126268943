import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import PlusIcon from "@material-ui/icons/Add";


const ParticipantInformation = ({
  getFieldProps,
  values,
  touched,
  errors,
  addProtocolParticipants,
  handleRemoveParticipantItem,
}) => {
  return (
    <div>
      {values?.clientMails?.map((participant, index,key) => {
        const clientError =
          (errors.clientMails?.length &&
            errors.clientMails[index]) ||
          {};
        const clientTouch =
          (touched.clientMails?.length &&
            touched.clientMails[index]) ||
          {};

        return (
          <div className="row mt-2">
            <div className="col col-11">
              {" "}
              <TextField
                label="Email"
                className="w-100"
                {...getFieldProps(
                  `clientMails[${index}].email`
                )}
              />
            </div>
           
            <div className="col col-1">
              <span
                aria-label="Delete"
                className="btn mt-5 btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                onClick={() => {
                  handleRemoveParticipantItem(index);
                }}
              >
                <DeleteIcon />
              </span>
            </div>

            {
            clientTouch.email && clientError.email
            ? (
              <div className="mt-2 ml-2">
                <div className="text-danger">
                  {clientError["email"]
                    ? clientError["email"]
                    : clientError["email   "]}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}

      <div className="col col-6 d-flex align-items-end mt-4 p-0">
        
        <Button onClick={addProtocolParticipants} className={`px-3`} type='button'>
        <PlusIcon />  Email əlavə et
        </Button>
      </div>
    </div>
  );
};

export default ParticipantInformation;
