import React, { useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDeleteNewsMutation } from "redux/api/News/newsApi";
import { makeStyles } from "@material-ui/core/styles";



export default function Remove(props) {

  const { onHide, show, id,  } = props;
  const form = useRef(null);

  const [
    deleteNews,
    { isLoading, isSuccess },
  ] = useDeleteNewsMutation();

  const onDeleteUser = (e) => {
    e.preventDefault();
    deleteNews({ id });
  };

  useEffect(() => {
    if (isSuccess) {
      onHide();
    }
  }, [isLoading]);

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteUser} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">Silməyinizə əminsiniz?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide(false)}>
            Ləğv
          </Button>
          <Button type="submit">Sil</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
