import userReducer from "./features/User/userSlice";
import { authApi } from "./api/Auth/authApi";
import { userApi } from "./api/Auth/userApi";
import { usersApi } from "./api/Users/usersApi";
import { newsSourcesPathApi } from "./api/NewsSourcesPath/newsSourcesPathApi";
import usersReducer from "./features/Users/usersSlice";
import { rolesApi } from "./api/Roles/rolesApi";
import { searchKeywordApi } from "./api/Searchkeyword/searchKeywordApi";
import { categoriesApi } from "./api/Categories/categoriesApi";
import { newsSourcesApi } from "./api/NewsSources/newsSourcesApi";
import { videoNewsApi } from "./api/VideoNews/VideoNewsApi";
import roleReducer from "./features/Role/RoleSlicer";
import keywordReducer from "./features/SearchKeyword/keywordSlice";
import { newsApi } from "./api/News/newsApi";
import sourcesReducer from "./features/NewsSources/newsSourcesSlices";
import newsReducer from "./features/News/newsSlices";
import categoriesReducer from "./features/Categories/categoriesSlice";
import templatesReducer from "./features/Categories/categoriesSlice";
import employeesReducer from './features/Employees/employeesSlice'
import {employeesApi} from "./api/Employees/employeesApi";
import proxiesReducer from "./features/Proxies/proxiesSlice";
import { proxiesApi } from "./api/Proxies/proxiesApi";
import { templatesApi } from "./api/ScrapeTemplates/templatesApi";
import {userAgentsApi} from "./api/UserAgents/UserAgentsApi"
import { tvChannelsApi } from "./api/TvChannels/TvChannelsApi";


export const reducers = {
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  user: userReducer,
  rolesSlicer: roleReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  usersState: usersReducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [searchKeywordApi.reducerPath]: searchKeywordApi.reducer,
  keyword: keywordReducer,
  [newsSourcesApi.reducerPath]: newsSourcesApi.reducer,
  sources: sourcesReducer,
  [newsApi.reducerPath]: newsApi.reducer,
  news: newsReducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  categories: categoriesReducer,
  
  [proxiesApi.reducerPath]: proxiesApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  employeesState: employeesReducer,
  proxies: proxiesReducer,
  [templatesApi.reducerPath]: templatesApi.reducer,
  templates: templatesReducer,
  [userAgentsApi.reducerPath]: userAgentsApi.reducer,
  [tvChannelsApi.reducerPath]: tvChannelsApi.reducer,
  [videoNewsApi.reducerPath]: videoNewsApi.reducer,
  [newsSourcesPathApi.reducerPath]: newsSourcesPathApi.reducer,

};

export const middleWares = [
  authApi.middleware,
  userApi.middleware,
  usersApi.middleware,
  rolesApi.middleware,
  employeesApi.middleware,
  searchKeywordApi.middleware,
  newsSourcesApi.middleware,
  newsApi.middleware,
  categoriesApi.middleware,
  proxiesApi.middleware,
  templatesApi.middleware,
  userAgentsApi.middleware,
  newsSourcesPathApi.middleware,
  tvChannelsApi.middleware,
  videoNewsApi.middleware
];
