import React, { useState,useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField/index";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {useUpdatePasswordMutation} from "redux/api/Users/usersApi"
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";
import * as Yup from "yup";




const initialValues = {
  password: "",
  
};
;

export default function ChangePassword(props) {
  const { onHide, id, show ,handleClick} = props;
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);


;
  const [values, setValues] = useState({
    password:" ",
  
    showPassword: false,
 
  });

  
  const [updatePassword, { isLoading, error, isSuccess }] =
  useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {

      handleClick()
      onHide();
    }
   
    }, [isLoading]);


  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };
  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password must consist of an uppercase letter, a lowercase letter, a number and a character."
      )
      .required("Şifrə daxil etməyiniz tələb olunur"),
     
  });

  const onChangeUserPassword = (values, resetForm, setSubmitting) => {

    const updateUser = {
      id: id,
      password: values.password,
  
 
    };

    updatePassword(updateUser)

  };

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onChangeUserPassword(values, resetForm, setSubmitting);
   
      resetForm()
    },
  });

  return (
    <div>
        <Modal
      onHide={onHide}
      show={show}
      size="sm"
      aria-labelledby="edit"
      centered
      className="modal-center pr-0"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="changePassword">Şifrəni Yenilə</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="password"
                    className="w-100"
                    type={values.showPassword ? "text" : "password"}
                    label="Şifrə"
                    // value={values.newPassword}
                    error={getInputClasses("password")}
                    {...formik.getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showPassword")
                            }
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
           
          

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Yadda saxla
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>

   

    </div>


  );
}
